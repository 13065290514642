import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PaymentState,
	PharmacyTaxNoteDto,
	TaxNoteSource,
} from '@services/src/models';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

export const EditNoteAction = (): EssityColumnProps<PharmacyTaxNoteDto> => {
	const history = useHistory();

	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		width: 50,
		render: (item: PharmacyTaxNoteDto) => (
			<Tooltip title="Edytuj">
				<Button
					type="primary"
					shape="circle"
					size="small"
					disabled={item.taxNoteSource !== TaxNoteSource.Portal}
					onClick={() => {
						history.push(`/pharmacyEditNote/${item.id}`);
					}}
					icon={<EditOutlined />}
				/>
			</Tooltip>
		),
	};
};

const canDeleteTaxNote = (item: PharmacyTaxNoteDto) => {
	return (
		item.taxNoteSource === TaxNoteSource.Portal &&
		item.paymentState === PaymentState.Created &&
		!item.hasCorrections
	);
};

export const DeleteNoteAction = (
	refresh: () => void
): EssityColumnProps<PharmacyTaxNoteDto> => {
	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		width: 50,
		render: (item: PharmacyTaxNoteDto) => {
			return (
				<Popconfirm
					title="Czy na pewno chcesz usunąć notę?"
					okText="Yes"
					cancelText="No"
					placement="bottom"
					disabled={!canDeleteTaxNote(item)}
					onConfirm={() => {
						ajaxByUser('Poprawnie usunięto notę.', () =>
							getEssityApiClient().then((api) =>
								api.pharmacyTaxNote
									.deleteMethod({ body: { id: item.id } })
									.then(() => refresh())
							)
						);
					}}
				>
					<Tooltip title="Usuń">
						<Button
							type="primary"
							shape="circle"
							size="small"
							disabled={!canDeleteTaxNote(item)}
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Popconfirm>
			);
		},
	};
};
