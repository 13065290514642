import './PaginatedEssityTable.less';

import { MenuUnfoldOutlined } from '@ant-design/icons';
import ErrorCode from '@helper/errorCode';
import { Checkbox, Col, Layout, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
	RowSelectionType,
	SorterResult,
	TableRowSelection,
} from 'antd/lib/table/interface';
import clsx from 'clsx';
import ColumnHelper from 'components/shared/paginatedEssityTable/ColumnHelper';
import { useRootData } from 'hooks/hook';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { deepObserve } from 'mobx-utils';
import React, { Key, ReactNode, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit/Menu';
import { IGridStore } from 'stores/GridStore';
import { GridNameType, ITableColumnStore } from 'stores/TableColumnStore';

import EssityTable from '../../../layout/EssityTable';
import { showNotExpectedErrorModal } from '../../../layout/Modals';
import IRenderActionModel from './actions/RenderAction';
import { EssityColumnExtender } from './EssityColumnExtender';
import { EssityColumnProps } from './GridHelper';
import {
	AntPagination,
	SieveModel,
	mapAntSorterToSieve,
	mapEssityFiltersToSieveFilters,
} from './SieveModels';
import { EssityTableEvents } from './TableEvents';
import TableSideBar, { ISideFilter } from './TableSideBar';

interface IHeaderSelectState {
	indeterminate: boolean;
	checked: boolean;
}

interface IProps<T> {
	getPagedResult: (
		sieve: SieveModel,
		filter?: any,
		abortSignal?: AbortSignal
	) => Promise<any>;
	getRowKey: (record: T) => string | number;
	columns: EssityColumnProps<T>[];

	gridName: GridNameType;
	columnsSelector?: boolean;

	noSpinnerOnRefresh?: boolean;

	// identyfikator załadowanych danych - jeśli się zmieni, zostanie wywołany refresh().
	// Przydatne do kasowania/aktualizacji rekordów w gridzie (zamiast jawnego wywoływania refresh() z nadrzędnego komponentu)
	dataIdentifier?: string | undefined;
	expandedRowRender?: React.ReactNode | undefined;
	className?: string;
	rowClassName?: (record: T, index: number | string) => string;
	renderActions?: (props: IRenderActionModel<T>) => JSX.Element;
	detailsUrl?: string;
	hasRowSelection?: boolean;
	hasSelectAll?: boolean;
	getRowSelection?: (array: T[]) => void;
	getRowValuesSelection?: (array: T[]) => void;
	selectedRows?: Array<T>;
	selectedList?: string;
	hidePersonalizationSettings?: boolean;
	filterByTeryt?: boolean;
	onRow?: (record: T, rowIndex: number) => EssityTableEvents;
	sideBarFilters?: {
		filters: ISideFilter[];
		path: string;
		siderWidth?: number;
	};
	isLoaded?: () => void;
	getFilters?: (
		antFilters: Record<keyof T, string[]>,
		antSorter: SorterResult<T>
	) => void;
	clearFilters?: () => void;
	rowSelectionType?: RowSelectionType;
	selectedOne?: (value: any) => void;
	selectedRowColumn?: (record: any) => string | number;
	clearSelectionOnSearching: boolean;
	showClearFilterButton: boolean;
	hasExtendedRowSelection?: boolean;
}

const PaginatedEssityTable = <T extends any>(
	props: IProps<T> & { children?: ReactNode }
) => {
	const {
		getPagedResult,
		getRowKey,
		columnsSelector,
		noSpinnerOnRefresh,
		expandedRowRender,
		rowClassName,
		getRowValuesSelection,
		detailsUrl,
		hasRowSelection,
		getRowSelection,
		hasSelectAll,
		hidePersonalizationSettings,
		onRow,
		sideBarFilters,
		getFilters,
		clearFilters,
		rowSelectionType,
		selectedOne,
		selectedRowColumn,
		clearSelectionOnSearching,
		showClearFilterButton,
	} = props;

	const [loaded, setLoaded] = useState<boolean>();
	const [results, setResults] = useState<T[] | undefined>();
	const [previousTabAbort, setPreviousTabAbort] = useState<
		AbortController | undefined
	>();

	const menuState = useMenuState({ placement: 'bottom-end', visible: true });

	const [
		headerSelectState,
		setHeaderSelectState,
	] = useState<IHeaderSelectState>({
		indeterminate: false,
		checked: false,
	});

	const gridName: GridNameType = props.gridName;

	let columnExtender: EssityColumnExtender<T> = new EssityColumnExtender<T>(
		gridName,
		columnsSelector,
		hidePersonalizationSettings,
		showClearFilterButton
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	let match = useRouteMatch<{ filter: string }>();


	const singleMode = rowSelectionType === 'radio';

	tableColumnStore.setCurrentTable(gridName);
	useEffect(() => {
		const abortController = new AbortController();

		refresh(abortController.signal);

		return () => {
			disposer();
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (loaded) {
			menuState.setVisible(false);
		}
	}, [loaded]);

	useEffect(() => {
		if (props.selectedRows) {
			gridStore.setSelectedKeys(
				props.selectedRows.map((x) => getRowKey(x))
			);

			gridStore.setSelectedValues(props.selectedRows);

			if (
				props.selectedRows.length ===
				gridStore.getGridPagination(props.gridName).total
			) {
				setHeaderSelectState({
					checked: true,
					indeterminate: false,
				});
			} else if (props.selectedRows.length > 0) {
				setHeaderSelectState({
					checked: false,
					indeterminate: true,
				});
			} else {
				gridStore.setSelectedKeys([]);
				gridStore.setSelectedValues([]);
			}
		}
	}, [props.selectedRows]);

	useEffect(() => {
		if (props.selectedList) refresh();
	}, [props.selectedList]);

	useEffect(() => {
		if (match.params.filter && sideBarFilters) {
			if (previousTabAbort) {
				previousTabAbort.abort();
			}
			const abortController = new AbortController();
			setPreviousTabAbort(abortController);
			gridStore.clearGridPagination(gridName);
			if (clearSelectionOnSearching) {
				gridStore.clearSelection();
			}
			refresh(abortController.signal);
		}
	}, [match.params.filter]);

	const refresh = (abortSignal?: AbortSignal) => {
		if (!props.selectedRows) {
			gridStore.setSelectedKeys([]);
			gridStore.setSelectedValues([]);
			gridStore.setExcludedKeys([]);
			gridStore.allRowsSelected.set(false);
			setHeaderSelectState({ checked: false, indeterminate: false });
		}

		setLoaded(false);
		let sieve = getSieveModelFromGridStore();
		fetchSieveResults(sieve, abortSignal);
	};

	const handleAntTableChange = (
		antPagination: AntPagination,
		antFilters: Record<keyof T, string[]>,
		antSorter: SorterResult<T>
	) => {
		setLoaded(false);
		let sieve = new SieveModel();

		let savedEssityFilters = gridStore.getGridFilters(gridName);

		if (savedEssityFilters)
			sieve.filters = mapEssityFiltersToSieveFilters(
				savedEssityFilters,
				props.columns
			);

		sieve.page = antPagination.current;
		sieve.pageSize = antPagination.pageSize;
		sieve.sorts = mapAntSorterToSieve(antSorter);

		gridStore.saveGridSorter(gridName, antSorter);

		fetchSieveResults(sieve);
	};

	const getSieveModelFromGridStore = () => {
		let sieve = new SieveModel();

		let savedFilter = gridStore.getGridFilters(gridName);

		let filterEntries = savedFilter?.entries();

		if (savedFilter && filterEntries !== undefined) {
			props.columns.forEach((col: any) => {
				if (savedFilter!.has(col.key) && col.dataIndex) {
					const val = savedFilter?.get(col.dataIndex);
					if (val) val.filterType = col.filter;

					savedFilter?.set(col.dataIndex, val);
				}
			});
		}

		const savedAntPagination = gridStore.getGridPagination(gridName);
		const savedAntSorter = gridStore.getGridSorter(gridName);
		const savedLabels = gridStore.getLabels(gridName);

		if (savedFilter) {
			sieve.filters = mapEssityFiltersToSieveFilters(
				savedFilter,
				props.columns
			);
		}

		if (savedLabels) {
			sieve.labels = gridStore.getLabels(gridName);
		}

		if (savedAntPagination) {
			sieve.page =
				savedAntPagination.current && savedAntPagination.current >= 1
					? savedAntPagination.current
					: 1;
			sieve.pageSize = savedAntPagination.pageSize;
		}
		if (savedAntSorter) {
			sieve.sorts = mapAntSorterToSieve(savedAntSorter);
		}
		return sieve;
	};

	const fetchSieveResults = (
		sieve: SieveModel,
		abortSignal?: AbortSignal
	) => {
		let filter = undefined;
		props.sideBarFilters?.filters.find((x) => {
			if (x.subItems) {
				var subItem = x.subItems?.find(
					(s) => s.key === match.params.filter
				);
				if (subItem) {
					filter = subItem?.filter;
				}
			} else {
				if (x.key === match.params.filter) filter = x?.filter;
			}
		});
		sieve.labels = gridStore.getLabels(gridName);
		getPagedResult(sieve, filter, abortSignal)
			.then((data: any) => {
				let antPagination: AntPagination = new AntPagination(true);
				if (data) {
					antPagination.current = data.currentPage;
					antPagination.pageSize = data.pageSize;
					antPagination.total = data.rowCount;
					antPagination.onShowSizeChange = (current, size) => {
						gridStore.saveGridPageSize(gridName, size);
					};
				}
				if (
					gridStore.getSelectedKeys() &&
					gridStore.getSelectedKeys().length > 0 &&
					!singleMode
				) {
					gridStore.setSelectedValues(
						_.uniqBy(
							[
								...gridStore.getSelectedValues()
							],
							(element) => getRowKey(element)
						)
					);
				}
				gridStore.saveGridPagination(gridName, antPagination);
				setLoaded(true);
				setResults(data!.results ? data!.results : data);
				props.isLoaded && props.isLoaded();
			})
			.catch((e: any) => {
				if (e?.code !== ErrorCode.AbortError) {
					showNotExpectedErrorModal(e);
					setLoaded(true);
				}
			});
	};

	const excludeFromCollection = (
		source: any[] | undefined,
		excludeCollection: any[]
	) => {
		return (
			source
				?.filter((r: any) => !excludeCollection.includes(getRowKey(r)))
				.map((r) => getRowKey(r)) ?? []
		);
	};

	const excludeKeys = (source: React.Key[], excludeKeys: React.Key[]) => {
		return (
			source?.filter((row: React.Key) => !excludeKeys.includes(row)) ?? []
		);
	};

	let antSorter = gridStore.getGridSorter(gridName);
	let columnsWithFilters = columnExtender.applyFiltersAndSorter(
		props.columns,
		antSorter,
		() => {
			gridStore.clearGridPagination(gridName);
			refresh();
		},
		(columnKey: Key) => {
			return gridStore.getColumnFilter(gridName, columnKey) !== undefined;
		}
	);

	let visibleColumnsWithFilters = columnsWithFilters;

	visibleColumnsWithFilters = visibleColumnsWithFilters.sort((a, b) => {
		if (a.dataIndex && b.dataIndex) return 0;
		else if (a.dataIndex) return -1;
		else if (b.dataIndex) return 1;
		return 0;
	});

	visibleColumnsWithFilters.forEach((col) => {
		col.onFilterDropdownVisibleChange = (visible: boolean) => {
			gridStore.markFilterVisibleChange(
				gridName,
				col.dataIndex as React.Key,
				visible
			);
		};
	});

	useEffect(() => {
		if (gridStore.searching.get()) {
			gridStore.searching.set(false);
			gridStore.clearGridPagination(gridName);
			if (clearSelectionOnSearching) {
				gridStore.clearSelection();
			}
			refresh();
		}
	}, [gridStore.searching.get()]);

	const disposer = deepObserve(gridStore.filters.get(gridName), () => {
		if (clearSelectionOnSearching) {
			gridStore.clearSelection();
		}
	});

	useEffect(() => {
		if (gridStore.allRowsSelected.get()) {
			const newKeys = excludeFromCollection(
				results,
				gridStore.getExcludedKeys()
			);
			gridStore.setSelectedKeys(newKeys);
		}
	}, [results]);

	const resultKeys = (results ?? []).map((r) => getRowKey(r));
	const initializePagination = () => {
		const gridPagination = gridStore.getGridPagination(gridName);
		const allColumns = (props.columns as EssityColumnProps<T>[])
			.filter((column) => column.dataIndex)
			.map((column) => column.key);
		const allColumnsCount = allColumns.length;

		const visibleColumnsCount = 0;

		gridPagination.showTotal = (total: number): React.ReactElement => {
			return (
				<Space>
					{!hidePersonalizationSettings && (
						<span>{`Widoczna liczba kolumn: ${visibleColumnsCount}/${allColumnsCount}`}</span>
					)}
					<span>{`Ilość wierszy: ${total}`}</span>
				</Space>
			);
		};
		return gridPagination;
	};

	const selectMenu = (
		<Menu
			{...menuState}
			className={clsx(
				'ant-menu ant-menu-sub account-menu-vertical z-index-up',
				menuState.visible && !loaded && 'visibility-hidden'
			)}
		>
			<MenuItem
				key="selectAll"
				className="ant-menu-item sub-menu-item"
				{...menuState}
				onClick={() => {
					if (gridStore.allRowsSelected.get()) {
						menuState.setVisible(false);

						let intersection = resultKeys.filter(
							(x) => !gridStore.getSelectedKeys().includes(x)
						);

						gridStore.setSelectedKeys([
							...gridStore.getSelectedKeys(),
							...intersection,
						]);

						let excludedIntersection = gridStore
							.getExcludedKeys()
							.filter((x) => !intersection.includes(x));

						gridStore.setExcludedKeys(excludedIntersection);

						if (gridStore.getExcludedKeys().length > 0) {
							setHeaderSelectState({
								checked: false,
								indeterminate: true,
							});
						} else {
							setHeaderSelectState({
								checked: true,
								indeterminate: false,
							});
						}

						return;
					}

					const intersectedValues = gridStore.selectedKeys.filter(
						(k) => !resultKeys.includes(k)
					);

					const selectedKeysTmp = _.uniq([
						...intersectedValues,
						...resultKeys,
					]);

					gridStore.setSelectedKeys(selectedKeysTmp);
					getRowSelection &&
						getRowSelection(gridStore.getSelectedValues());
					let selectedFullList = _.uniqBy(
						[...resultKeys, ...gridStore.getSelectedValues()],
						(element) => getRowKey(element)
					).filter((x) => selectedKeysTmp.indexOf(getRowKey(x)) >= 0);

					getRowValuesSelection &&
						getRowValuesSelection(selectedFullList);
					gridStore.setSelectedValues(selectedFullList);

					if (
						gridStore.getSelectedKeys().length ===
						gridStore.getGridPagination(props.gridName).total
					) {
						setHeaderSelectState({
							checked: true,
							indeterminate: false,
						});
						gridStore.allRowsSelected.set(true);
						gridStore.setExcludedKeys([]);
					} else {
						setHeaderSelectState({
							checked: false,
							indeterminate: true,
						});
					}

					const items = results!.filter((x) =>
						gridStore.getSelectedKeys().includes(getRowKey(x))
					);

					gridStore.selectedValues.push(...items);

					getRowSelection &&
						getRowSelection(gridStore.getSelectedKeys() as any[]);

					getRowValuesSelection &&
						getRowValuesSelection(gridStore.getSelectedValues());

					menuState.setVisible(false);
				}}
			>
				Zaznacz wszystkie wiersze na stronie
			</MenuItem>
			<MenuItem
				key="deselectAll"
				className="ant-menu-item sub-menu-item"
				{...menuState}
				onClick={() => {
					if (gridStore.allRowsSelected.get()) {
						const newExcludedCollection = [
							...gridStore.getExcludedKeys(),
							...resultKeys,
						];

						const newKeys = excludeFromCollection(
							results,
							newExcludedCollection
						);

						gridStore.setExcludedKeys(
							Array.from(_.uniq(newExcludedCollection))
						);
						gridStore.setSelectedKeys(newKeys);

						if (
							gridStore.getExcludedKeys().length ===
							gridStore.getGridPagination(props.gridName).total
						) {
							setHeaderSelectState({
								indeterminate: false,
								checked: false,
							});
							gridStore.setSelectedKeys([]);
							gridStore.setExcludedKeys([]);
							gridStore.allRowsSelected.set(false);
						} else {
							setHeaderSelectState({
								indeterminate: true,
								checked: false,
							});
						}
					} else {
						const leftSelection = excludeKeys(
							gridStore.getSelectedKeys(),
							resultKeys
						);

						if (leftSelection.length > 0) {
							setHeaderSelectState({
								indeterminate: true,
								checked: false,
							});

							gridStore.setSelectedKeys(leftSelection);
						} else {
							setHeaderSelectState({
								indeterminate: false,
								checked: false,
							});
							gridStore.setSelectedKeys([]);
							gridStore.setExcludedKeys([]);
						}
					}
					menuState.setVisible(false);

					let intersection = gridStore
						.getSelectedValues()
						.filter((x) => !resultKeys?.includes(getRowKey(x)));

					gridStore.setSelectedValues(intersection);

					getRowSelection &&
						getRowSelection(gridStore.getSelectedKeys() as any[]);

					getRowValuesSelection &&
						getRowValuesSelection(gridStore.getSelectedValues());
				}}
			>
				Odznacz wszystkie wiersze na stronie
			</MenuItem>
		</Menu>
	);

	let extendedSelectionMode: TableRowSelection<T>;

	if (singleMode) {
		extendedSelectionMode = {
			type: rowSelectionType,
			getCheckboxProps: (record: any) => {
				const getSelectedRecordKey = selectedRowColumn
					? selectedRowColumn
					: getRowKey;
				const isDisabled =
					props.selectedRows?.some(
						(row: any) =>
							getSelectedRecordKey(row) === getRowKey(record)
					) ?? false;
				return {
					className: clsx(isDisabled && 'ant-radio-checked'),
					disabled: isDisabled,
				};
			},
			onSelect: (
				record: T,
				selected: boolean,
				selectedRows: Object[]
			) => {
				selectedOne && selectedOne(selectedRows);
			},
		};
	} else {
		extendedSelectionMode = {
			type: rowSelectionType,
			selectedRowKeys: gridStore.getSelectedKeys(),
			columnTitle: (
				<div className="table-header-checkbox">
					<Checkbox
						disabled={!hasSelectAll}
						indeterminate={headerSelectState.indeterminate}
						checked={headerSelectState.checked}
						onChange={(e: CheckboxChangeEvent) => {
							if (
								e.target.className ===
								'ant-checkbox-indeterminate'
							) {
								setHeaderSelectState({
									indeterminate: false,
									checked: false,
								});
								gridStore.setSelectedKeys([]);
								gridStore.setExcludedKeys([]);
								gridStore.allRowsSelected.set(false);
							} else if (e.target.checked) {
								setHeaderSelectState({
									indeterminate: false,
									checked: true,
								});
								gridStore.toggleAllRowsSelected(
									(allSelected: boolean) => {
										gridStore.setExcludedKeys([]);

										if (
											allSelected &&
											!gridStore.getSelectedKeys().length
										) {
											const newKeys =
												results?.map((r) =>
													getRowKey(r)
												) ?? [];
											gridStore.setSelectedKeys(newKeys);
										} else {
											gridStore.setSelectedKeys([]);
											gridStore.allRowsSelected.set(
												false
											);
										}
									}
								);
							} else {
								setHeaderSelectState({
									indeterminate: false,
									checked: false,
								});
								gridStore.setSelectedKeys([]);
								gridStore.setExcludedKeys([]);
								gridStore.allRowsSelected.set(false);
							}

							getRowSelection &&
								getRowSelection(
									gridStore.getSelectedKeys() as any[]
								);

							getRowValuesSelection &&
								getRowValuesSelection(
									results!.filter((x) =>
										gridStore
											.getSelectedKeys()
											.includes(getRowKey(x))
									)
								);
						}}
					></Checkbox>
					<div className="container">
						<MenuButton
							{...menuState}
							className="menu"
							aria-label="Menu zaznaczania wierszy"
						>
							<MenuUnfoldOutlined />
						</MenuButton>

						{selectMenu}
					</div>
				</div>
			),
			onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
				if (gridStore.allRowsSelected.get()) {
					const excludedRecordsSet: Set<string | number> = new Set(
						gridStore.getExcludedKeys()
					);
					const newExcludedCollection = excludeFromCollection(
						results,
						selectedRowKeys
					);
					newExcludedCollection.forEach((r) =>
						excludedRecordsSet.add(r)
					);
					const newKeys = excludeFromCollection(
						results,
						newExcludedCollection
					);

					gridStore.setExcludedKeys(
						excludeKeys(
							Array.from(excludedRecordsSet),
							selectedRowKeys
						)
					);

					gridStore.setSelectedKeys(newKeys);

					getRowSelection &&
						getRowSelection(gridStore.getSelectedKeys() as any[]);

					getRowValuesSelection &&
						getRowValuesSelection(selectedRows);

					if (
						gridStore.excludedKeys.length ===
						gridStore.getGridPagination(props.gridName).total
					) {
						setHeaderSelectState({
							checked: false,
							indeterminate: false,
						});
						gridStore.setExcludedKeys([]);
						gridStore.setSelectedKeys([]);
						gridStore.allRowsSelected.set(false);
					} else if (gridStore.excludedKeys.length > 0) {
						setHeaderSelectState({
							checked: false,
							indeterminate: true,
						});
					} else {
						setHeaderSelectState({
							checked: true,
							indeterminate: false,
						});
					}
				} else {
					const intersectedValues = gridStore.selectedKeys.filter(
						(k) => !resultKeys.includes(k)
					);

					const selectedKeysTmp = _.uniq([
						...intersectedValues,
						...selectedRowKeys,
					]);

					gridStore.setSelectedKeys(selectedKeysTmp);
					getRowSelection &&
						getRowSelection(gridStore.getSelectedValues());
					let selectedFullList = _.uniqBy(
						[...selectedRows, ...gridStore.getSelectedValues()],
						(element) => getRowKey(element)
					).filter((x) => selectedKeysTmp.indexOf(getRowKey(x)) >= 0);

					if (gridStore.excludedKeys.length > 0) {
						setHeaderSelectState({
							checked: false,
							indeterminate: true,
						});
					} else if (
						gridStore.getSelectedKeys().length ===
						gridStore.getGridPagination(props.gridName).total
					) {
						setHeaderSelectState({
							checked: true,
							indeterminate: false,
						});
					} else {
						if (gridStore.getSelectedKeys().length > 0) {
							setHeaderSelectState({
								checked: false,
								indeterminate: true,
							});
						} else {
							setHeaderSelectState({
								checked: false,
								indeterminate: false,
							});
						}
					}

					getRowValuesSelection &&
						getRowValuesSelection(selectedFullList);

					gridStore.setSelectedValues(selectedFullList);
				}
			},
		};
	}

	const onSelect = (record: any, selected: any, selectedRows: any) => {
		var items = gridStore.getSelectedValues();

		if (selected) {
			items.push(record);
		} else {
			const item = items.findIndex((x) => x.id === record.id);

			items.splice(item, 1);
		}

		const newValues = _.uniqBy(items, (e) => {
			return e.id;
		});

		gridStore.setSelectedValues(newValues);
		gridStore.setSelectedKeys(newValues.map((x) => x.id));
	};

	const onSelectAll = (selected: any, selectedRows: any, changeRows: any) => {
		if (selected) {
			const mixedKeys = [
				...gridStore.getSelectedKeys(),
				...changeRows.map((x: any) => x.id),
			];
			const mixedValues = [
				...gridStore.getSelectedValues(),
				...changeRows,
			];

			const newKeys = Array.from(new Set(mixedKeys));
			const newValues = Array.from(new Set(mixedValues));

			gridStore.setSelectedKeys(newKeys);
			gridStore.setSelectedValues(newValues);
		} else {
			const changeRowsIds = changeRows.map((x: any) => x.id);

			const vals = gridStore
				.getSelectedValues()
				.filter((x) => !changeRowsIds.includes(x.id));

			var newSelectedValues = Array.from(new Set(vals)).filter(
				(x) => x !== undefined
			);
			gridStore.setSelectedValues(newSelectedValues);
			gridStore.setSelectedKeys(newSelectedValues.map((x) => x.id));
		}
	};

	const defaultSelectionMode = {
		onSelect: onSelect,
		onSelectAll: onSelectAll,
		selectedRowKeys: gridStore.getSelectedKeys(),
	};

	const getRowSelectionMode = () => {
		if (!hasRowSelection) {
			return false;
		}

		if (props.hasExtendedRowSelection) {
			return extendedSelectionMode;
		}

		return defaultSelectionMode;
	};

	const renderTable = (
		<>
			{(props.renderActions) && (
				<Row justify="space-between" gutter={[0, 8]} className="p-xxs">
					{props.renderActions && (
						<Col>
							{props.renderActions({
								results: results,
								getRowKey: getRowKey,
								getSieveModel: getSieveModelFromGridStore,
							})}
						</Col>
					)}
				</Row>
			)}

			<EssityTable
				className={clsx('essity-table', props.className)}
				rowSelection={getRowSelectionMode()}
				loading={
					(!noSpinnerOnRefresh || results === undefined) && !loaded
				}
				expandedRowRender={expandedRowRender}
				columns={visibleColumnsWithFilters}
				scroll={{ x: 120 * visibleColumnsWithFilters.length }}
				pagination={initializePagination()}
				rowKey={(r: T) => getRowKey(r).toString()}
				onChange={(
					p: AntPagination,
					f: Record<keyof T, string[]>,
					s: SorterResult<T>
				) => {
					handleAntTableChange(p, f, s);
					getFilters && getFilters(f, s);
				}}
				dataSource={results}
				rowClassName={rowClassName}
				detailsUrl={detailsUrl}
				onRow={onRow}
			/>
		</>
	);

	const sideBarEnhanced = (hasSideBar: boolean): JSX.Element => (
		<>
			{hasSideBar ? (
				<Layout className="site-layout-background sidebar-layout">
					<TableSideBar
						gridName={gridName}
						selectedFilter={match.params.filter}
						{...sideBarFilters!}
					/>
					<section className="ant-layout-content sidebar-content">
						{renderTable}
					</section>
				</Layout>
			) : (
				renderTable
			)}
		</>
	);

	return <>{sideBarEnhanced(sideBarFilters !== undefined)}</>;
};

PaginatedEssityTable.defaultProps = {
	hasSelectAll: true,
	rowSelectionType: 'checkbox',
	clearSelectionOnSearching: true,
	showClearFilterButton: false,
};

export default observer(PaginatedEssityTable);
