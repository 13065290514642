import { TaxNoteOrderState } from '@services/src/models';

export const valueMap = new Map<TaxNoteOrderState, string>([
	[TaxNoteOrderState.Accepted, 'Zaakceptowane'],
    [TaxNoteOrderState.Assigned, 'Przypisane'],
    [TaxNoteOrderState.NotAssigned, 'Nie przypisane'],
    [TaxNoteOrderState.ReadyToSend, 'Gotowe do wysłania'],
    [TaxNoteOrderState.Refused, 'Odrzucono'],
    [TaxNoteOrderState.SentToSap, 'Wysłano do SAP']
]);

export const map = (value: TaxNoteOrderState): string => valueMap.get(value) ?? '';