import TermsOfUseModal, {
	ITermsOfUseModal,
} from '@components/termsOfUse/TermsOfUseModal';
import { useRootData } from '@hooks/hook';
import { SignoutReason } from '@services/mappers/SignoutReason';
import * as SignoutReasonMapper from '@services/mappers/SignoutReason';
import { Button, Checkbox, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Footer } from 'layout/Footer';
import { observer } from 'mobx-react-lite';
import { User } from 'oidc-client';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';

import { GlobalUserManager } from './userManager';

const Login = () => {
	const history = useHistory();
	const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState<boolean>();

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [signoutReason, setSignoutReason] = useState<
		SignoutReason | undefined
	>();

	useEffect(() => {
		GlobalUserManager.UserManager()
			.getUser()
			.then((user: User | null) => {
				if (user) {
					accountDetailsStore.oidcUser.set(user);
					history.push('/orders');
				}
			});

		setSignoutReason(
			localStorage.getItem('signOutReason') as SignoutReason
		);
		localStorage.removeItem('signOutReason');
	}, []);

	const termsOfUseModalRef = useRef<ITermsOfUseModal>() as React.RefObject<ITermsOfUseModal>;

	return (
		<>
			<div className="login">
				<Space direction="vertical">
					<img
						width={250}
						height={104}
						src={process.env.PUBLIC_URL + '/logo.png'}
					/>
					<Button
						type="primary"
						shape="round"
						style={{ width: '250px' }}
						size="large"
						disabled={!acceptedTermsOfUse}
						onClick={() => {
							GlobalUserManager.UserManager().signinRedirect();
							localStorage.removeItem('signOutReason');
						}}
					>
						Zaloguj
					</Button>
					<Space direction="horizontal">
						<Checkbox
							checked={acceptedTermsOfUse}
							onChange={(e: CheckboxChangeEvent) => {
								setAcceptedTermsOfUse(e.target.checked);
							}}
						></Checkbox>
						Akceptuję
						<a
							onClick={(e) => {
								e.stopPropagation();
								termsOfUseModalRef.current?.showModal();
							}}
						>
							warunki użytkowania
						</a>
					</Space>

					{signoutReason && (
						<p style={{ textAlign: 'center', fontWeight: 'bold' }}>
							{SignoutReasonMapper.map(signoutReason)}
						</p>
					)}
				</Space>
			</div>
			<TermsOfUseModal ref={termsOfUseModalRef} allowCancel={false} />
			<Footer />
		</>
	);
};

export default observer(Login);
