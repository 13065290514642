import * as Yup from 'yup';

export const PharmacyNoteValidationSchema = () =>
	Yup.object().shape({
		noteDate: Yup.date().required('Data noty jest wymagana.'),
		noteNumber: Yup.string().required('Numer noty jest wymagany.'),
		month: Yup.date().required('Miesiąc jest wymagany.'),
		year: Yup.date().required('Rok jest wymagany.'),
		noteValue: Yup.number().required('Wartość noty jest wymagana.'),
	});
