import { SettingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import EssityTooltip from 'layout/EssityTooltip';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';

const SettingsMenu = () => {
	const history = useHistory();

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					history.push('/importedDocuments');
				}}
			>
				Zaimportowane dokumenty
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					history.push('/createdDocuments');
				}}
			>
				Utworzone dokumenty
			</Menu.Item>
		</Menu>
	);
	return (
		<EssityTooltip placement="left" title="Ustawienia">
			<Dropdown overlay={menu} trigger={['click']}>
				<Button
					type="primary"
					shape="circle"
					size="large"
					icon={<SettingOutlined />}
				></Button>
			</Dropdown>
		</EssityTooltip>
	);
};

export default observer(SettingsMenu);
