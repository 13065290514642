import './Menu.less';

import React, { useEffect, useRef, useState } from 'react';
import { Toolbar, ToolbarItem, useToolbarState } from 'reakit/Toolbar';

import ApplicationMenuItem from './ApplicationMenuItem';
import ApplicationSubMenuItem from './ApplicationSubMenuItem';
import { MenuItemSchema, SubMenuItemSchema } from './MenuItems';
import SubMenu from './SubMenu';

interface IProps {
	items: Array<MenuItemSchema | SubMenuItemSchema>;
}

interface IMenuItem {
	definition: MenuItemSchema | SubMenuItemSchema;
	width: number;
	isVisible: boolean;
}

const ApplicationMenu: React.FC<IProps> = (props) => {
	const toolbar = useToolbarState();
	const [menuConfiguration, setMenuConfiguration] = useState<IMenuItem[]>([]);
	const [toolbarWidth, setToolbarWidth] = useState<number>(0);
	const targetRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setMenuConfiguration(
			props.items.map(
				(x): IMenuItem => ({ definition: x, isVisible: true, width: 0 })
			)
		);
	}, [props.items]);

	useEffect(() => {
		if (targetRef.current) {
			setToolbarWidth(targetRef.current.clientWidth);
		}
	}, []);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				const cr = entry.contentRect;
				if (entry.target === targetRef.current) {
					setToolbarWidth(cr.width);
				}
			}
		});
		if (targetRef.current) {
			observer.observe(targetRef.current);
		}
		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, []);

	const setMenuItemWidth = (key: string, width: number) => {
		const item = menuConfiguration.find((x) => x.definition.key === key);
		if (item) {
			item.width = width;
			setMenuConfiguration([...menuConfiguration]);
		}
	};

	const renderMenuItem = (item: MenuItemSchema) => {
		return (
			<ApplicationMenuItem
				key={item.key}
				toolbar={toolbar}
				item={item}
				setMenuItemWidth={setMenuItemWidth}
			/>
		);
	};

	const renderSubMenuItem = (item: SubMenuItemSchema) => {
		return (
			<ApplicationSubMenuItem
				toolbar={toolbar}
				item={item}
				setMenuItemWidth={setMenuItemWidth}
			/>
		);
	};

	const moreItemsMenuItem = (
		items: Array<MenuItemSchema | SubMenuItemSchema>
	) => {
		const fullSubMenu: SubMenuItemSchema = {
			items: items,
			text: '...',
			key: 'expand',
		};

		return (
			<ToolbarItem
				{...toolbar}
				item={fullSubMenu}
				as={SubMenu}
				className="ant-menu-submenu-horizontal ant-menu-overflowed-submenu"
			/>
		);
	};

	const moreItemsWidth: number = 50;
	const toRender = [];
	let width = 0;
	for (let i = 0; i < menuConfiguration.length; i++) {
		if (
			toolbarWidth > 0 &&
			width +
				menuConfiguration[i].width +
				(i < menuConfiguration.length - 1 ? moreItemsWidth : 0) <=
				toolbarWidth
		) {
			if ((menuConfiguration[i].definition as any).items === undefined) {
				const item = menuConfiguration[i].definition as MenuItemSchema;
				toRender.push(renderMenuItem(item));
			} else {
				const item = menuConfiguration[i]
					.definition as SubMenuItemSchema;
				toRender.push(renderSubMenuItem(item));
			}

			width += menuConfiguration[i].width;
		} else {
			toRender.push(moreItemsMenuItem(props.items.slice(i)));
			break;
		}
	}

	return (
		<>
			<Toolbar
				ref={targetRef}
				{...toolbar}
				aria-label="Menu"
				role="navigation"
				className="top-menu-bar ant-menu ant-menu-light ant-menu-root ant-menu-horizontal d-flex justify-center"
			>
				{toRender}
			</Toolbar>
		</>
	);
};

export default ApplicationMenu;
