import { Button, Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { AntPagination } from 'components/shared/paginatedEssityTable/SieveModels';
import {
	EssityTableEvents,
	getTableEvents,
} from 'components/shared/paginatedEssityTable/TableEvents';
import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps<T> {
	detailsUrl?: string;
	onRow?: (
		record: any,
		rowIndex: number
	) => void | ((record: T, rowIndex: number) => EssityTableEvents);
	getRowClassName?: (record: any, index: number) => any;
	setDataSource?: (data: any[]) => void;
	bordered?: boolean;
	rowSelection?: TableRowSelection<T>;
	expandedRowRender?: React.ReactNode | undefined;
	handleAntTableChange?: (
		antPagination: AntPagination,
		antFilters: Record<keyof T, string[]>,
		antSorter: SorterResult<T>
	) => void;
}

const EssityTable = <T extends any>(
	props: IProps<T> & { children?: ReactNode } & TableProps<any> &
		RouteComponentProps &
		any
) => {
	const { detailsUrl, onRow, bordered, columns, ...restProps } = props;

	const handleRowDoubleClick = (idRow: string) => {
		props.history.push(`${props.detailsUrl}/${idRow}`);
	};

	const handleOnRow = (record: any, rowIndex: number) => {
		let tableEvents = getTableEvents();
		if (props.detailsUrl) {
			tableEvents.onDoubleClick = (
				event: React.MouseEvent<HTMLElement, MouseEvent>
			) => {
				if (!props.rowKey) return undefined;
				return handleRowDoubleClick(
					(props.rowKey as any)(record) as string
				);
			};
		}

		return tableEvents;
	};

	const getAlternateBg = (record: any, index: number) =>
		`${index % 2 === 1 ? 'row-alternate' : ''} ${
			props.rowClassName ? (props.rowClassName as any)(record, index) : ''
		}`;

	const getRowClassName = (record: any, index: number) => {
		if (props.getRowClassName) {
			return props.getRowClassName(record, index);
		}

		return getAlternateBg(record, index);
	};

	return (
		<Table
			{...restProps}
			style={{ display: 'block' }}
			size="small"
			bordered={bordered}
			columns={columns}
			onChange={
				props.handleAntTableChange
					? (props.handleAntTableChange as any)
					: props.onChange
			}
			onRow={(onRow ? onRow : handleOnRow) as any}
			rowClassName={getRowClassName}
		></Table>
	);
};

export default withRouter(EssityTable);
