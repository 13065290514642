import { DocumentSource } from '@services/src/models';

export const mapings = new Map<DocumentSource, string>([
	[DocumentSource.ORDERSIMPORT, 'Import zleceń'],
	[DocumentSource.PHARMACIESIMPORT, 'Import aptek'],
	[DocumentSource.PHARMACIESIMPORTTEMPLATE, 'Import szablonu aptek'],
	[DocumentSource.PRICELISTIMPORT, 'Import cennika'],
	[DocumentSource.ZPOSPEXPORT, 'Export raportu NFZ'],
	[DocumentSource.UMXPHARMACIESIMPORT, 'Import UMX'],
	[DocumentSource.FARMAPROMPHARMACIESIMPORT, 'Import Farmaprom'],
	[DocumentSource.NHFSTATEIMPORT, 'Import szablonu NFZ'],
	[DocumentSource.NOTEEXPORT, 'Export noty płatności'],
]);

export const map = (value: DocumentSource): string => mapings.get(value) ?? '';
