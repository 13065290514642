import { CloudDownloadOutlined } from '@ant-design/icons';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { TableWithTitle } from '@components/shared/TableWithTitle';
import { ajaxByUser } from '@helper/api';
import { useTitleHook } from '@hooks/useTitleHook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	DocumentAllOptionalParams,
	DocumentCategory,
	SourceDocumentDto,
} from '@services/src/models';
import { Button, Divider, Tooltip } from 'antd';
import React from 'react';

import { ImportedDocumentsTableColumns } from './ImportedDocumentsTableColumns';

const columns: EssityColumnProps<SourceDocumentDto>[] = [
	...ImportedDocumentsTableColumns,
	{
		title: '',
		key: 'operation',
		fixed: 'right',
		width: 50,
		render: (item: SourceDocumentDto) => (
			<Tooltip title="Pobierz">
				<Button
					type="primary"
					shape="circle"
					size="small"
					onClick={() =>
						ajaxByUser('Poprawnie pobrano plik', () =>
							getEssityApiClient().then((api) =>
								api.document
									.getById(item.id!)
									.then((result) => {
										DownloadFile({
											response: result,
										});
									})
							)
						)
					}
					icon={<CloudDownloadOutlined />}
				/>
			</Tooltip>
		),
	},
];

export const ImportedDocuments = () => {

	useTitleHook('Zaimportowane dokumenty');

	return (
		<>
			<Divider />
			<TableWithTitle title="Zaimportowane dokumenty">
				<PaginatedEssityTable<SourceDocumentDto>
					columns={columns}
					gridName="importedDocuments"
					hasRowSelection={false}
					hidePersonalizationSettings={true}
					sideBarFilters={undefined}
					getRowKey={(r: SourceDocumentDto) => r.id!}
					getPagedResult={async (sieve: SieveModel, abortSignal) => {
						const parameters: DocumentAllOptionalParams = {
							page: sieve.page,
							pageSize: sieve.pageSize,
							sorts: sieve.sorts,
							abortSignal: abortSignal,
							filters: sieve.filters,
							documentCategory: DocumentCategory.IMPORT,
						};

						const api = await getEssityApiClient();
						return await api.document.allForPharmacy(parameters);
					}}
				/>
			</TableWithTitle>
		</>
	);
};
