/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNotePaymentConfirmationMappers";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNotePaymentConfirmation. */
export class PharmacyTaxNotePaymentConfirmation {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNotePaymentConfirmation.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse>
   */
  changeState(options?: Models.PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams): Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse>;
  /**
   * @param callback The callback
   */
  changeState(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeState(options: Models.PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  changeState(options?: Models.PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      changeStateOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const changeStateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/changeState",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.PharmacyTaxNotePaymentConfirmationChangeStateCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
