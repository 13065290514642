import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

interface IProps {
	customAction?: () => void;
}

export const BackButton = (props: IProps) => {
	const history = useHistory();
	return (
		<Button
			shape="round"
			size="large"
			onClick={() =>
				props.customAction ? props.customAction() : history.goBack()
			}
		>
			Wróć
		</Button>
	);
};
