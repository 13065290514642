import { DeleteOutlined } from '@ant-design/icons';
import { BackButton } from '@components/shared/buttons/BackButton';
import { ajaxByUser } from '@helper/api';
import formatHelpers, { DateFormat } from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import {
	CreateNoteResponseDto,
	InternalTaxNoteType,
	OrderDto,
	PharmacyTaxNoteCreateResponse,
	PharmacyTaxNoteDetailsDto,
	PharmacyTaxNoteUpdateResponse,
} from '@services/src/models';
import {
	Button,
	Col,
	Input,
	Modal,
	Popconfirm,
	Row,
	Space,
	Tooltip,
	Upload,
} from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
} from 'forms/FormikFormItems';
import { showWarning } from 'layout/Modals';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import styles from './styles/PharmacyNote.module.less';
import { PharmacyNoteValidationSchema } from './validation/PharmacyNoteValidationSchema';

interface IProps {
	readOnly: boolean;
	noteId?: string;
	pharmacyId?: string;
	setAttachment: (attachment: Blob | undefined) => void;
	editMode?: boolean;
}

interface IAttachmentState {
	file: File;
}

interface IState {
	noteDate?: Moment;
	noteNumber?: number;
	noteValue?: number;
	year?: Moment;
	month?: Moment;
	ordersValue?: number;
}

export const PharmacyNoteDetails = (props: IProps) => {
	const [orders, setOrders] = useState<OrderDto[]>();
	const [note, setNote] = useState<PharmacyTaxNoteDetailsDto>();
	const [attachment, setAttachment] = useState<IAttachmentState>();
	const [state, setState] = useState<IState>({});

	const history = useHistory();

	useEffect(() => {
		if (props.noteId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getByIdForPharmacy(
					props.noteId!
				);
				setNote(result);
				if (result.data?.length) {
					const blob = new Blob([
						new Uint8Array(
							result.data,
							result.data.byteOffset,
							result.data.length
						),
					]);
					props.setAttachment(blob);
					setAttachment({ file: new File([blob], result.fileName!) });

					setOrders(result.orders as OrderDto[]);
				}
			})();
		}
	}, [props.noteId]);

	const getInitialValues = () => {
		if (note) {
			const dateObject = new Date(
				moment().year(),
				note.month!,
				moment().day()
			);

			return {
				noteDate: moment(note?.noteDate),
				noteNumber: note?.noteNumber,
				noteValue: note?.noteValue,
				ordersValue: note?.assignedOrdersValue,
				settledOrdersValue: note?.settledOrdersValue,
				taxNoteType: note?.taxNoteType,
				month: moment(dateObject).add(-1, 'month'),
				year: moment(
					new Date(note!.year!, moment().month(), moment().day())
				),
			};
		} else {
			return {
				month: state?.month
					? state?.month
					: moment(
							new Date(
								moment().year(),
								moment().add(-1, 'month').month(),
								moment().day()
							)
					  ),
				year: state?.year ? state.year : moment(),
				noteDate: state?.noteDate,
				noteNumber: state.noteNumber,
				noteValue: state.noteValue,
				ordersValue: state.ordersValue,
			};
		}
	};

	const hasSelectedOrders = (): boolean => {
		if (!orders) {
			return false;
		}

		return orders.length > 0;
	};

	const uploadProps = {
		name: 'file',
		multiple: false,
		customRequest: async (options: RcCustomRequestOptions) => {
			const { onSuccess } = options;

			props.setAttachment(options.file);
			setAttachment({ file: options.file });
			onSuccess({}, options.file);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		accept: '.pdf',
		showUploadList: false,
	};

	const RenderNoteDetails = (formikProps: FormikProps<any>) => {
		return (
			<>
				{(!note || note?.internalTaxNoteType === InternalTaxNoteType.Main) && (
					<Row>
						<Col span={24}>
							<FFieldLabel label="Nazwa załącznika" />
							<Row>
								<Col span={10}>
									<Input
										value={attachment?.file.name}
										readOnly={true}
									/>
								</Col>
								<Col span={5} offset={1}>
									<Upload {...uploadProps}>
										<Button
											type="primary"
											size="middle"
											shape="round"
											disabled={
												props.readOnly &&
												!props.editMode
											}
											style={{ width: '100%' }}
										>
											Dodaj załącznik
										</Button>
									</Upload>
								</Col>
								<Col span={1}>
									<Popconfirm
										title="Czy na pewno chcesz usunąć załącznik?"
										okText="Tak"
										cancelText="Nie"
										placement="bottom"
										disabled={
											!attachment?.file ||
											(props.readOnly && !props.editMode)
										}
										onConfirm={() => {
											props.setAttachment(undefined);
											setAttachment(undefined);
										}}
									>
										<Tooltip title="Usuń załącznik">
											<Button
												type="primary"
												icon={<DeleteOutlined />}
												shape="circle"
												style={{ width: '100%' }}
												disabled={
													!attachment?.file ||
													(props.readOnly &&
														!props.editMode)
												}
												danger
											/>
										</Tooltip>
									</Popconfirm>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<Row>
					<Col span={10}>
						<FFieldLabel label="Data noty" />
						<Field
							label="Data noty"
							component={FDatePicker}
							name="noteDate"
							mode="date"
							style={{ width: '100%' }}
							disabled={props.readOnly && !props.editMode}
							changeData={(value: any) => {
								setState({
									...state,
									noteDate: value,
								});
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Numer noty" />
						<Field
							component={FInput}
							changeData={(value: any) => {
								setState({ ...state, noteNumber: value });
							}}
							placeholder="Numer noty"
							name="noteNumber"
							readOnly={props.readOnly && !props.editMode}
						/>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={5}>
						<FFieldLabel label="Miesiąc" />
						<Field
							component={FDatePicker}
							placeholder="Miesiąc"
							name="month"
							style={{ width: '100%' }}
							inputReadOnly={
								props.readOnly || hasSelectedOrders()
							}
							changeData={(value: any) => {
								setState({ ...state, month: value });
							}}
							picker="month"
							format={DateFormat.Month}
						/>
					</Col>
					<Col span={5}>
						<FFieldLabel label="Rok" />
						<Field
							component={FDatePicker}
							placeholder="Rok"
							style={{ width: '100%' }}
							name="year"
							inputReadOnly={
								props.readOnly || hasSelectedOrders()
							}
							changeData={(value: any) => {
								setState({ ...state, year: value });
							}}
							picker="year"
							format={DateFormat.Year}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Wartość noty" />
						<Field
							component={FInputNumber}
							placeholder="Wartość noty"
							name="noteValue"
							min={Number.MIN_SAFE_INTEGER}
							readOnly={props.readOnly && !props.editMode}
							changeData={(value: number) => {
								setState({ ...state, noteValue: value });
							}}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const createOrUpdateNote = async (values: any) => {
		if (!attachment) {
			showWarning('Załącznik noty jest wymagany.');
			return;
		}

		const data = await new Response(attachment!.file).arrayBuffer();

		const fileExtension = attachment?.file.name.split('.').pop();

		if (props.editMode) {
			ajaxByUser('Poprawnie zaktualizowano notę.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.updateForPharmacy({
							body: {
								pharmacyTaxNoteId: note?.id,
								noteDate: values.noteDate.toDate(),
								noteNumber: values.noteNumber,
								noteValue: values.noteValue,
								fileName: attachment?.file.name,
								mimeType: attachment?.file.type,
								fileExtension: fileExtension,
								data: new Uint8Array(data),
							},
						})
						.then((response: PharmacyTaxNoteUpdateResponse) => {
							history.push(
								`/pharmacyPayments/${response.pharmacyTaxNoteGroupId}/${response.pharmacyId}`
							);
						})
				)
			);
		} else {
			ajaxByUser('Poprawnie dodano notę.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.createForPharmacy({
							body: {
								year: values.year.year(),
								month: values.month.month() + 1,
								noteDate: values.noteDate.toDate(),
								noteNumber: values.noteNumber,
								noteValue: values.noteValue,
								mimeType: attachment?.file.type,
								data: new Uint8Array(data),
								fileName: attachment?.file.name,
								fileExtension: fileExtension,
							},
						})
						.then((result: PharmacyTaxNoteCreateResponse) => {
							if (result) {
								const dto = result as CreateNoteResponseDto;

								const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;

								Modal.success({
									centered: true,
									maskClosable: true,
									title: `Utworzono notę z numerem: ${dto.pharmacyTaxNoteIdInternalNumber}`,
									onOk: () => {
										history.push(path);
									},
									onCancel: () => {
										history.push(path);
									},
								});
							}
						})
				)
			);
		}
	};

	return (
		<Space direction="vertical" className={styles.details}>
			<h2 style={{ marginLeft: 0 }}>Szczegóły noty</h2>
			{note?.internalNumber && (
				<h3>Nota apteczna {note?.internalNumber}</h3>
			)}
			<Space direction="vertical" className={styles.form}>
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={getInitialValues()}
					enableReinitialize
					validationSchema={PharmacyNoteValidationSchema}
					onSubmit={(values, actions: FormikActions<any>) =>
						createOrUpdateNote(values)
					}
					render={(formikProps: FormikProps<any>) => (
						<Space direction="vertical" style={{ width: '100%' }}>
							{RenderNoteDetails(formikProps)}
							<Space
								direction="horizontal"
								className={styles.actions}
							>
								<BackButton />
								<Button
									type="primary"
									shape="round"
									size="large"
									className={styles.save}
									disabled={props.readOnly && !props.editMode}
									onClick={() => formikProps.submitForm()}
								>
									Zapisz
								</Button>
							</Space>
						</Space>
					)}
				></Formik>
			</Space>
		</Space>
	);
};
