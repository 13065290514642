import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderDto, SearchType } from '@services/src/models';
import moment from 'moment';
import React from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import styles from './styles/GlobalSearch.module.less';

interface IProps {
	searchType: SearchType;
}

export const GlobalSearchTable = (props: IProps) => {
	const gridName = 'orders';

	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const columns: EssityColumnProps<OrderDto>[] = [
		...tableColumnStore.getTable(gridName),
	];

	return (
		<PaginatedEssityTable<OrderDto>
			columns={columns}
			gridName={gridName}
			className={styles.table}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			sideBarFilters={undefined}
			onRow={(record: OrderDto) => {
				return {
					onDoubleClick: () => {
						globalSearchStore.filterData.set({
							...globalSearchStore.filterData.get(),
							selectedOrderId: record.id!,
						});
					},
				};
			}}
			getRowKey={(r: OrderDto) => r.id!}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const api = await getEssityApiClient();

				const searchData = globalSearchStore.filterData.get();

				const searchType = searchData.searchType;

				var fromDate = undefined;
				var toDate = undefined;

				if (searchType === 1) {
					fromDate = moment().add(-1, 'years');
				} else if (searchType === 3) {
					fromDate = searchData.dateFrom;
					toDate = searchData.dateTo;
				}

				return await api.order.searchPagedForPharmacy({
					sieveFilters: sieve.filters,
					sieveSorts: sieve.sorts,
					sievePage: sieve.page,
					sievePageSize: sieve.pageSize,
					searchType: props.searchType,
					searchText: searchData.searchText,
					voivodeshipIds: searchData.searchVoivodeships,
					orderStates: searchData.searchOrderStates,
					abortSignal,
					fromDate: fromDate?.toDate(),
					toDate: toDate?.toDate(),
				});
			}}
		/>
	);
};
