import {
	ContentType,
	StaticContentModal,
} from '@components/staticContent/StaticContentModal';
import { Divider, Layout, Space } from 'antd';
import React, { useState } from 'react';

import packageJson from '../../package.json';

export const Footer = () => {
	const [contentType, setContentType] = useState<ContentType>(
		ContentType.None
	);

	return (
		<Layout.Footer style={{ textAlign: 'center', width: '100vw' }}>
			v{packageJson.version}{' '}
			{process.env.REACT_APP_BUILD && (
				<>build {process.env.REACT_APP_BUILD}</>
			)}
			<Space direction="horizontal">
				<Divider type="vertical" />
				<a
					onClick={() => {
						setContentType(ContentType.TermsOfUse);
					}}
				>
					Warunki użytkowania
				</a>
				<Divider type="vertical" />
				<a
					onClick={() => {
						setContentType(ContentType.PrivacyPolicy);
					}}
				>
					Polityka prywatności
				</a>
				<Divider type="vertical" />
				<a
					onClick={() => {
						setContentType(ContentType.CookiesPolicy);
					}}
				>
					Polityka plików cookies
				</a>
				<Divider type="vertical" />© 2021 Copyright Essity Poland Sp.z
				o.o.
			</Space>
			<StaticContentModal
				contentType={contentType}
				onCancel={() => {
					setContentType(ContentType.None);
				}}
			/>
		</Layout.Footer>
	);
};
