/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/importModelMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a ImportModel. */
export class ImportModel {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a ImportModel.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importOrders(options?: Models.ImportModelImportOrdersOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importOrders(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importOrders(options: Models.ImportModelImportOrdersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importOrders(options?: Models.ImportModelImportOrdersOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importOrdersOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importNhfState(options?: Models.ImportModelImportNhfStateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importNhfState(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importNhfState(options: Models.ImportModelImportNhfStateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importNhfState(options?: Models.ImportModelImportNhfStateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importNhfStateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importPriceList(options?: Models.ImportModelImportPriceListOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importPriceList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importPriceList(options: Models.ImportModelImportPriceListOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importPriceList(options?: Models.ImportModelImportPriceListOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importPriceListOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importPharmacies(options?: Models.ImportModelImportPharmaciesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importPharmacies(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importPharmacies(options: Models.ImportModelImportPharmaciesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importPharmacies(options?: Models.ImportModelImportPharmaciesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importPharmaciesOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ImportModelImportFarmapromPharmaciesResponse>
   */
  importFarmapromPharmacies(options?: Models.ImportModelImportFarmapromPharmaciesOptionalParams): Promise<Models.ImportModelImportFarmapromPharmaciesResponse>;
  /**
   * @param callback The callback
   */
  importFarmapromPharmacies(callback: msRest.ServiceCallback<Models.ImportFarmapromPharmaciesStatusDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importFarmapromPharmacies(options: Models.ImportModelImportFarmapromPharmaciesOptionalParams, callback: msRest.ServiceCallback<Models.ImportFarmapromPharmaciesStatusDto>): void;
  importFarmapromPharmacies(options?: Models.ImportModelImportFarmapromPharmaciesOptionalParams | msRest.ServiceCallback<Models.ImportFarmapromPharmaciesStatusDto>, callback?: msRest.ServiceCallback<Models.ImportFarmapromPharmaciesStatusDto>): Promise<Models.ImportModelImportFarmapromPharmaciesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importFarmapromPharmaciesOperationSpec,
      callback) as Promise<Models.ImportModelImportFarmapromPharmaciesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ImportModelImportUmxPharmaciesResponse>
   */
  importUmxPharmacies(options?: Models.ImportModelImportUmxPharmaciesOptionalParams): Promise<Models.ImportModelImportUmxPharmaciesResponse>;
  /**
   * @param callback The callback
   */
  importUmxPharmacies(callback: msRest.ServiceCallback<Models.ImportUmxPharmaciesStatusDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importUmxPharmacies(options: Models.ImportModelImportUmxPharmaciesOptionalParams, callback: msRest.ServiceCallback<Models.ImportUmxPharmaciesStatusDto>): void;
  importUmxPharmacies(options?: Models.ImportModelImportUmxPharmaciesOptionalParams | msRest.ServiceCallback<Models.ImportUmxPharmaciesStatusDto>, callback?: msRest.ServiceCallback<Models.ImportUmxPharmaciesStatusDto>): Promise<Models.ImportModelImportUmxPharmaciesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importUmxPharmaciesOperationSpec,
      callback) as Promise<Models.ImportModelImportUmxPharmaciesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importNhfNoteTemplate(options?: Models.ImportModelImportNhfNoteTemplateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importNhfNoteTemplate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importNhfNoteTemplate(options: Models.ImportModelImportNhfNoteTemplateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importNhfNoteTemplate(options?: Models.ImportModelImportNhfNoteTemplateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importNhfNoteTemplateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importPaymentSAPConfirmation(options?: Models.ImportModelImportPaymentSAPConfirmationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importPaymentSAPConfirmation(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importPaymentSAPConfirmation(options: Models.ImportModelImportPaymentSAPConfirmationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importPaymentSAPConfirmation(options?: Models.ImportModelImportPaymentSAPConfirmationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importPaymentSAPConfirmationOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const importOrdersOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importOrders",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const importNhfStateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importNhfState",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const importPriceListOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importPriceList",
  formDataParameters: [
    Parameters.file,
    Parameters.validFrom,
    Parameters.priceListGroup0
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const importPharmaciesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importPharmacies",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const importFarmapromPharmaciesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importFarmapromPharmacies",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: Mappers.ImportFarmapromPharmaciesStatusDto
    },
    default: {}
  },
  serializer
};

const importUmxPharmaciesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importUmxPharmacies",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: Mappers.ImportUmxPharmaciesStatusDto
    },
    default: {}
  },
  serializer
};

const importNhfNoteTemplateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importNhfNoteTemplate",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const importPaymentSAPConfirmationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "import/Import/importPaymentSAPConfirmation",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};
