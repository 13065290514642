import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PharmacyTaxNoteDto,
	PharmacyTaxNotePaymentWithNotesDto,
} from '@services/src/models';
import { Table } from 'antd';
import EssityTable from 'layout/EssityTable';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ITableColumnStore } from 'stores/TableColumnStore';

import { PaymentTableColumns } from './PaymentTableColumns';
import { DeleteNoteAction, EditNoteAction } from './PharmacyNotesTableActions';
import styles from './styles/PharmacyNotes.module.less';

interface IProps {
	groupId: string;
}

export const PharmacyNotesTable = (props: IProps) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const [notes, setNotes] = useState<PharmacyTaxNotePaymentWithNotesDto[]>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const history = useHistory();

	const gridName = 'pharmacyNotes';

	const refreshNotes = () => {
		setIsBusy(true);
		ajaxCatch(() =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNotePayment
					.getPharmacyTaxNotePaymentWithNotesForPharmacy({
						pharmacyTaxNoteGroupId: props.groupId,
					})
					.then((result) => {
						if (result) {
							setNotes(result);
						}
					})
					.finally(() => setIsBusy(false))
			)
		);
	};

	const columns: EssityColumnProps<PharmacyTaxNoteDto>[] = [
		...tableColumnStore.getTable(gridName),
		EditNoteAction(),
		DeleteNoteAction(refreshNotes),
	];

	useEffect(() => refreshNotes(), []);

	return (
		<EssityTable
			className={styles.table}
			columns={PaymentTableColumns}
			gridName={gridName}
			dataSource={notes}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			sideBarFilters={undefined}
			loading={isBusy}
			pagination={false}
			rowKey={(r: PharmacyTaxNotePaymentWithNotesDto) => r.paymentId}
			expandable={{
				expandedRowRender: (
					record: PharmacyTaxNotePaymentWithNotesDto
				) => (
					<>
						<Table
							columns={columns}
							dataSource={record.pharmacyTaxNotes}
							pagination={false}
							onRow={(record: PharmacyTaxNoteDto) => {
								return {
									onDoubleClick: () => {
										history.push(
											`/pharmacyNote/${record.id}`
										);
									},
								};
							}}
						/>
						<div className={styles.summary}>
							<h3>Podsumowanie</h3>
							<p>{record.noteValueSum}</p>
							<p>{record.ordersValueSum}</p>
							<p>{record.settledOrdersValueSum}</p>
						</div>
					</>
				),
				expandedRowKeys: notes?.map((x) => x.paymentId),
			}}
		/>
	);
};
