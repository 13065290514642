import { DictionaryDto, DictionaryValueDto } from '@services/src/models';
import { Select } from 'antd';
import React from 'react';
interface IProps {
	name: string;
	dictionary: DictionaryDto | undefined;
	onChange?: (items: number[]) => void;
	mode: mode;
	className: string;
}

export type mode = 'multiple' | 'tags' | undefined;

export const DictionaryFilter = (props: IProps) => {
	return (
		<div id={props.name} className={props.className}>
			{props.name}
			<Select
				mode={props.mode}
				size="small"
				placeholder="Kliknij by filtrować"
				onChange={props.onChange}
			>
				{props.dictionary?.values?.map((item: DictionaryValueDto) => {
					return (
						<Select.Option key={item.id} value={item.id!}>
							{item.value}
						</Select.Option>
					);
				})}
			</Select>
		</div>
	);
};
