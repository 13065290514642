import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import * as DocumentSourceMapper from '@services/mappers/DocumentSource';
import { DocumentSource, SourceDocumentDto } from '@services/src/models';

export const CreatedDocumentsTableColumns: EssityColumnProps<SourceDocumentDto>[] = [
	{
		dataIndex: 'id',
		key: 'id',
		title: 'Id',
	},
	{
		dataIndex: 'name',
		key: 'name',
		title: 'Nazwa',
	},
	{
		dataIndex: 'source',
		key: 'source',
		title: 'Kategoria',
		render: (value: DocumentSource) => DocumentSourceMapper.map(value),
	},
	{
		dataIndex: 'createdDate',
		key: 'createdDate',
		title: 'Data',
		render: (value?: Date) =>
			formatHelpers.formatDate(value, 'DD.MM.YYYY, HH:mm:ss'),
	},
	{
		dataIndex: 'createdByUserId',
		key: 'createdByUserId',
		title: 'ID użytkownika',
	},
];
