import { OrderState } from '@services/src/models';

export const valueMap = new Map<OrderState, string>([
	[OrderState.Imported, 'Zaimportowane'],
	[OrderState.Reported, 'Zaraportowane'],
	[OrderState.ApprovedByNhf, 'Zatwierdzone przez NFZ'],
	[OrderState.MarkedToCancel, 'Oznaczone do anulowania'],
	[OrderState.SentToCancel, 'Wysłane do anulowania'],
	[OrderState.Canceled, 'Anulowane'],
	[OrderState.SettledNhf, 'Rozliczone'],
	[OrderState.Restored, 'Przywrócone'],
]);

export const colorMap = new Map<OrderState, string>([
	[OrderState.Imported, '#f1d52f'],
	[OrderState.Reported, '#fd9e31'],
	[OrderState.ApprovedByNhf, '#117abd'],
	[OrderState.SettledNhf, '#64bc55'],
	[OrderState.MarkedToCancel, '#e95b4b'],
	[OrderState.SentToCancel, '#7f7f7f'],
	[OrderState.Canceled, '#333333'],
	[OrderState.Restored, '#f63da0'],
]);

export const map = (value: OrderState): string => valueMap.get(value) ?? '';
export const mapColor = (value: OrderState): string =>
	colorMap.get(value) ?? '';
