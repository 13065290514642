import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { IMapper } from './contracts/IMapper';
interface IProps<Type> {
	name: string;
	enum: Type[];
	enumMapper: IMapper<Type>;
	onChange?: (items: Type[]) => void;
	selectedValue?: Type[];
	mode: mode;
	className: string;
	disabled?: boolean;
}

export type mode = 'multiple' | 'tags' | undefined;

const EnumFilter = <Type, >(props: IProps<Type>) => {
	return (
		<div id={props.name} className={props.className}>
			{props.name}
			<Select
				mode={props.mode}
				size="small"
				placeholder="Kliknij by filtrować"
				onChange={(value) => props.onChange!(value as unknown as Type[])}
				value={props.selectedValue as unknown as SelectValue}
				disabled={props.disabled}
			>
				{props.enum.map((item: any) => {
					return (
						<Select.Option key={item} value={item}>
							{props.enumMapper.map(item)}
						</Select.Option>
					);
				})}
			</Select>
		</div>
	);
};

export default observer(EnumFilter);
