import { useRootData } from '@hooks/hook';
import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import './AddressSection.less'

const AddressSection = () => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const address = accountDetailsStore.account.get()?.address;

	return (
		<Space direction="vertical" size="small">
			<h2 style={{ marginLeft: 0 }}>
				{accountDetailsStore.oidcUser.get()?.profile.name}
			</h2>
			{address && (
				<h5 style={{ fontWeight: 'lighter', fontSize: 14 }}>
					{`${address.street} ${address?.city} ${address?.postalCode}`}
				</h5>
			)}
		</Space>
	);
};

export default observer(AddressSection);
