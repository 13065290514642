import { Empty, Space } from 'antd';
import React from 'react';

import styles from './styles/PharmacyNote.module.less';

interface IProps {
	blob?: Blob;
}

export const PharmacyNotePreview = (props: IProps) => {
	return (
		<Space direction="vertical" className={styles.preview}>
			<h2 style={{marginLeft: 0}}>Podgląd noty</h2>
			<div className={styles.viewer}>
				{props.blob ? (
					<iframe
						src={`/pdfjs/web/viewer.html?file=${URL.createObjectURL(
							props.blob
						)}`}
						width="100%"
						height="700px"
					></iframe>
				) : (
					<Empty />
				)}
			</div>
		</Space>
	);
};
