import { SearchOutlined } from '@ant-design/icons';
import { useRootData } from '@hooks/hook';
import { SearchType } from '@services/src/models';
import { Button, Dropdown, Menu } from 'antd';
import EssityTooltip from 'layout/EssityTooltip';
import React, { useState } from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

import GlobalSearchModal from './GlobalSearchModal';

interface IProps {}

const AccountMenu: React.FC<IProps> = (props) => {
	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const [searchType, setSearchType] = useState<SearchType>(SearchType.PESEL);

	const resetFilters = () => {
		globalSearchStore.filterData.set({
			searchText: undefined,
			searchVoivodeships: undefined,
			searchOrderStates: undefined,
			searchType: 1,
			dateFrom: null,
			dateTo: null,
		});
	};

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					setSearchType(SearchType.PESEL);
					resetFilters();
					globalSearchStore.visible.set(true);
				}}
			>
				Wyszukaj zlecenia po PESEL
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					setSearchType(SearchType.OrderNumber);
					resetFilters();
					globalSearchStore.visible.set(true);
				}}
			>
				Wyszukaj zlecenia po numerze
			</Menu.Item>
			<Menu.Item
				key="3"
				onClick={() => {
					setSearchType(SearchType.Status);
					resetFilters();
					globalSearchStore.visible.set(true);
				}}
			>
				Wyszukaj zlecenia po statusie
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<EssityTooltip placement="left" title="Wyszukiwanie globalne">
				<Dropdown overlay={menu} trigger={['click']}>
					<Button
						aria-label="global search"
						size="large"
						shape="circle"
						icon={<SearchOutlined />}
					/>
				</Dropdown>
			</EssityTooltip>
			<GlobalSearchModal searchType={searchType} />
		</>
	);
};

export default AccountMenu;
