/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Pharmacy. */
export class Pharmacy {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Pharmacy.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyAllResponse>
   */
  all(options?: Models.PharmacyAllOptionalParams): Promise<Models.PharmacyAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.PharmacyDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.PharmacyAllOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyDtoPagedResult>): void;
  all(options?: Models.PharmacyAllOptionalParams | msRest.ServiceCallback<Models.PharmacyDtoPagedResult>, callback?: msRest.ServiceCallback<Models.PharmacyDtoPagedResult>): Promise<Models.PharmacyAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PharmacyAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyCreateResponse>
   */
  create(options?: Models.PharmacyCreateOptionalParams): Promise<Models.PharmacyCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.PharmacyCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.PharmacyCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.PharmacyCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.PharmacyCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.PharmacyUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.PharmacyUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.PharmacyUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyPharmaciesWithUnasignedOrdersResponse>
   */
  pharmaciesWithUnasignedOrders(options?: Models.PharmacyPharmaciesWithUnasignedOrdersOptionalParams): Promise<Models.PharmacyPharmaciesWithUnasignedOrdersResponse>;
  /**
   * @param callback The callback
   */
  pharmaciesWithUnasignedOrders(callback: msRest.ServiceCallback<Models.PharmacyDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  pharmaciesWithUnasignedOrders(options: Models.PharmacyPharmaciesWithUnasignedOrdersOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyDtoPagedResult>): void;
  pharmaciesWithUnasignedOrders(options?: Models.PharmacyPharmaciesWithUnasignedOrdersOptionalParams | msRest.ServiceCallback<Models.PharmacyDtoPagedResult>, callback?: msRest.ServiceCallback<Models.PharmacyDtoPagedResult>): Promise<Models.PharmacyPharmaciesWithUnasignedOrdersResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      pharmaciesWithUnasignedOrdersOperationSpec,
      callback) as Promise<Models.PharmacyPharmaciesWithUnasignedOrdersResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.PharmacyDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyDto>, callback?: msRest.ServiceCallback<Models.PharmacyDto>): Promise<Models.PharmacyGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.PharmacyGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  changeStatus(options?: Models.PharmacyChangeStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  changeStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeStatus(options: Models.PharmacyChangeStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  changeStatus(options?: Models.PharmacyChangeStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      changeStatusOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyGetImportTemplateResponse>
   */
  getImportTemplate(options?: msRest.RequestOptionsBase): Promise<Models.PharmacyGetImportTemplateResponse>;
  /**
   * @param callback The callback
   */
  getImportTemplate(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getImportTemplate(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getImportTemplate(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.PharmacyGetImportTemplateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getImportTemplateOperationSpec,
      callback) as Promise<Models.PharmacyGetImportTemplateResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacy/Pharmacy",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.selectedIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyDtoPagedResult
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacy/Pharmacy",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePharmacyCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacy/Pharmacy",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const pharmaciesWithUnasignedOrdersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacy/Pharmacy/pharmaciesWithUnasignedOrders",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacy/Pharmacy/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyDto
    },
    default: {}
  },
  serializer
};

const changeStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacy/Pharmacy/changeStatus",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ChangeStatusCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getImportTemplateOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacy/Pharmacy/importTemplate",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    400: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
