import { OrderLines } from '@components/orderLines/OrderLines';
import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { useRootData } from '@hooks/hook';
import { SearchType } from '@services/src/models';
import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

import GlobalSearchFilters from './GlobalSearchFilters';
import { GlobalSearchTable } from './GlobalSearchTable';
import styles from './styles/GlobalSearch.module.less';

interface IProps {
	searchType: SearchType;
}

const GlobalSearchModal = (props: IProps) => {
	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const handleCancel = () => {
		globalSearchStore.visible.set(false);
	};

	const getModalTitle = (): string => {
		switch (props.searchType) {
			case SearchType.PESEL:
				return 'Wyszukaj zlecenia po PESEL';
			case SearchType.OrderNumber:
				return 'Wyszukaj zlecenia po numerze';
			case SearchType.Status:
				return 'Wyszukaj zlecenia po statusie';
		}
	};

	const filterData = globalSearchStore.filterData.get();

	return (
		<IsolatedContainer>
			<Modal
				title={getModalTitle()}
				visible={globalSearchStore.visible.get()}
				cancelText="Zamknij"
				width="95vw"
				centered
				okButtonProps={{ hidden: true }}
				cancelButtonProps={{ hidden: true }}
				maskClosable={false}
				onCancel={handleCancel}
				closable={filterData.selectedOrderId ? false : true}
				destroyOnClose
			>
				<>
					{filterData.selectedOrderId ? (
						<OrderLines
							orderId={filterData.selectedOrderId!}
							isInSearch={true}
							theme={styles}
						/>
					) : (
						<>
							<GlobalSearchFilters
								searchType={props.searchType}
							/>
							<div className={styles.tableContainer}>
								<GlobalSearchTable
									searchType={props.searchType}
								/>
							</div>
						</>
					)}
				</>
			</Modal>
		</IsolatedContainer>
	);
};

export default observer(GlobalSearchModal);
