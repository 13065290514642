/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/orderMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Order. */
export class Order {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Order.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderAllForPharmacyResponse>
   */
  allForPharmacy(options?: Models.OrderAllForPharmacyOptionalParams): Promise<Models.OrderAllForPharmacyResponse>;
  /**
   * @param callback The callback
   */
  allForPharmacy(callback: msRest.ServiceCallback<Models.OrderDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  allForPharmacy(options: Models.OrderAllForPharmacyOptionalParams, callback: msRest.ServiceCallback<Models.OrderDto>): void;
  allForPharmacy(options?: Models.OrderAllForPharmacyOptionalParams | msRest.ServiceCallback<Models.OrderDto>, callback?: msRest.ServiceCallback<Models.OrderDto>): Promise<Models.OrderAllForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allForPharmacyOperationSpec,
      callback) as Promise<Models.OrderAllForPharmacyResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderGetByIdForPharmacyResponse>
   */
  getByIdForPharmacy(id: string, options?: msRest.RequestOptionsBase): Promise<Models.OrderGetByIdForPharmacyResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getByIdForPharmacy(id: string, callback: msRest.ServiceCallback<Models.OrderDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getByIdForPharmacy(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.OrderDetailsDto>): void;
  getByIdForPharmacy(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OrderDetailsDto>, callback?: msRest.ServiceCallback<Models.OrderDetailsDto>): Promise<Models.OrderGetByIdForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdForPharmacyOperationSpec,
      callback) as Promise<Models.OrderGetByIdForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderSearchPagedForPharmacyResponse>
   */
  searchPagedForPharmacy(options?: Models.OrderSearchPagedForPharmacyOptionalParams): Promise<Models.OrderSearchPagedForPharmacyResponse>;
  /**
   * @param callback The callback
   */
  searchPagedForPharmacy(callback: msRest.ServiceCallback<Models.OrderDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchPagedForPharmacy(options: Models.OrderSearchPagedForPharmacyOptionalParams, callback: msRest.ServiceCallback<Models.OrderDto>): void;
  searchPagedForPharmacy(options?: Models.OrderSearchPagedForPharmacyOptionalParams | msRest.ServiceCallback<Models.OrderDto>, callback?: msRest.ServiceCallback<Models.OrderDto>): Promise<Models.OrderSearchPagedForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      searchPagedForPharmacyOperationSpec,
      callback) as Promise<Models.OrderSearchPagedForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderAllResponse>
   */
  all(options?: Models.OrderAllOptionalParams): Promise<Models.OrderAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.OrderDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.OrderAllOptionalParams, callback: msRest.ServiceCallback<Models.OrderDto>): void;
  all(options?: Models.OrderAllOptionalParams | msRest.ServiceCallback<Models.OrderDto>, callback?: msRest.ServiceCallback<Models.OrderDto>): Promise<Models.OrderAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.OrderAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeResponse>
   */
  getAllPaginatedOrdersForPharmacyInDateScope(options?: Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeOptionalParams): Promise<Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeResponse>;
  /**
   * @param callback The callback
   */
  getAllPaginatedOrdersForPharmacyInDateScope(callback: msRest.ServiceCallback<Models.OrderDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllPaginatedOrdersForPharmacyInDateScope(options: Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeOptionalParams, callback: msRest.ServiceCallback<Models.OrderDtoPagedResult>): void;
  getAllPaginatedOrdersForPharmacyInDateScope(options?: Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeOptionalParams | msRest.ServiceCallback<Models.OrderDtoPagedResult>, callback?: msRest.ServiceCallback<Models.OrderDtoPagedResult>): Promise<Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAllPaginatedOrdersForPharmacyInDateScopeOperationSpec,
      callback) as Promise<Models.OrderGetAllPaginatedOrdersForPharmacyInDateScopeResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeResponse>
   */
  getAllNotAssignedOrdersForPharmacyInDateScope(options?: Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeOptionalParams): Promise<Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeResponse>;
  /**
   * @param callback The callback
   */
  getAllNotAssignedOrdersForPharmacyInDateScope(callback: msRest.ServiceCallback<Models.OrderDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllNotAssignedOrdersForPharmacyInDateScope(options: Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeOptionalParams, callback: msRest.ServiceCallback<Models.OrderDto[]>): void;
  getAllNotAssignedOrdersForPharmacyInDateScope(options?: Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeOptionalParams | msRest.ServiceCallback<Models.OrderDto[]>, callback?: msRest.ServiceCallback<Models.OrderDto[]>): Promise<Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAllNotAssignedOrdersForPharmacyInDateScopeOperationSpec,
      callback) as Promise<Models.OrderGetAllNotAssignedOrdersForPharmacyInDateScopeResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderSearchResponse>
   */
  search(options?: Models.OrderSearchOptionalParams): Promise<Models.OrderSearchResponse>;
  /**
   * @param callback The callback
   */
  search(callback: msRest.ServiceCallback<Models.OrderSearchDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  search(options: Models.OrderSearchOptionalParams, callback: msRest.ServiceCallback<Models.OrderSearchDto[]>): void;
  search(options?: Models.OrderSearchOptionalParams | msRest.ServiceCallback<Models.OrderSearchDto[]>, callback?: msRest.ServiceCallback<Models.OrderSearchDto[]>): Promise<Models.OrderSearchResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      searchOperationSpec,
      callback) as Promise<Models.OrderSearchResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderSearchPagedResponse>
   */
  searchPaged(options?: Models.OrderSearchPagedOptionalParams): Promise<Models.OrderSearchPagedResponse>;
  /**
   * @param callback The callback
   */
  searchPaged(callback: msRest.ServiceCallback<Models.OrderDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchPaged(options: Models.OrderSearchPagedOptionalParams, callback: msRest.ServiceCallback<Models.OrderDto>): void;
  searchPaged(options?: Models.OrderSearchPagedOptionalParams | msRest.ServiceCallback<Models.OrderDto>, callback?: msRest.ServiceCallback<Models.OrderDto>): Promise<Models.OrderSearchPagedResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      searchPagedOperationSpec,
      callback) as Promise<Models.OrderSearchPagedResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.OrderGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.OrderDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.OrderDetailsDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OrderDetailsDto>, callback?: msRest.ServiceCallback<Models.OrderDetailsDto>): Promise<Models.OrderGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.OrderGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  markToCancel(options?: Models.OrderMarkToCancelOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  markToCancel(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  markToCancel(options: Models.OrderMarkToCancelOptionalParams, callback: msRest.ServiceCallback<void>): void;
  markToCancel(options?: Models.OrderMarkToCancelOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      markToCancelOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  restore(options?: Models.OrderRestoreOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  restore(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  restore(options: Models.OrderRestoreOptionalParams, callback: msRest.ServiceCallback<void>): void;
  restore(options?: Models.OrderRestoreOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      restoreOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderGetOrdersRealizationByNhfRealizationResponse>
   */
  getOrdersRealizationByNhfRealization(options?: Models.OrderGetOrdersRealizationByNhfRealizationOptionalParams): Promise<Models.OrderGetOrdersRealizationByNhfRealizationResponse>;
  /**
   * @param callback The callback
   */
  getOrdersRealizationByNhfRealization(callback: msRest.ServiceCallback<Models.OrderRealizationDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getOrdersRealizationByNhfRealization(options: Models.OrderGetOrdersRealizationByNhfRealizationOptionalParams, callback: msRest.ServiceCallback<Models.OrderRealizationDto[]>): void;
  getOrdersRealizationByNhfRealization(options?: Models.OrderGetOrdersRealizationByNhfRealizationOptionalParams | msRest.ServiceCallback<Models.OrderRealizationDto[]>, callback?: msRest.ServiceCallback<Models.OrderRealizationDto[]>): Promise<Models.OrderGetOrdersRealizationByNhfRealizationResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getOrdersRealizationByNhfRealizationOperationSpec,
      callback) as Promise<Models.OrderGetOrdersRealizationByNhfRealizationResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/orders/Order",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.selectedIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDto
    },
    default: {}
  },
  serializer
};

const getByIdForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/orders/Order/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDetailsDto
    },
    default: {}
  },
  serializer
};

const searchPagedForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/orders/Order/searchPaged",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.searchText1,
    Parameters.searchType,
    Parameters.fromDate,
    Parameters.toDate,
    Parameters.orderStates,
    Parameters.voivodeshipIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDto
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.orderGroupId0,
    Parameters.selectedIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDto
    },
    default: {}
  },
  serializer
};

const getAllPaginatedOrdersForPharmacyInDateScopeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order/paginatedNotAssignedOrdersForPharmacyInDateScope",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.pharmacyId,
    Parameters.year0,
    Parameters.month0,
    Parameters.includeOrdersAssignedToPharmacyTaxNoteId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getAllNotAssignedOrdersForPharmacyInDateScopeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order/notAssignedOrdersForPharmacyInDateScopeQuery",
  queryParameters: [
    Parameters.pharmacyId,
    Parameters.year0,
    Parameters.month0,
    Parameters.includeOrdersAssignedToPharmacyTaxNoteId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrderDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const searchOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order/search",
  queryParameters: [
    Parameters.orderGroupId1,
    Parameters.searchText1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrderSearchDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const searchPagedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order/searchPaged",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.searchText1,
    Parameters.searchType,
    Parameters.fromDate,
    Parameters.toDate,
    Parameters.orderStates,
    Parameters.voivodeshipIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDto
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDetailsDto
    },
    default: {}
  },
  serializer
};

const markToCancelOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "orders/Order/markToCancel",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.MarkToCancelCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const restoreOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "orders/Order/restore",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RestoreCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getOrdersRealizationByNhfRealizationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orders/Order/ordersRealizationByNhfRealization",
  queryParameters: [
    Parameters.nhfRealizationId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrderRealizationDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
