/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as operations from "./operations";
import { PolandPharmacyRxAPIContext } from "./polandPharmacyRxAPIContext";

class PolandPharmacyRxAPI extends PolandPharmacyRxAPIContext {
  // Operation groups
  account: operations.Account;
  dictionary: operations.Dictionary;
  dictionaryValue: operations.DictionaryValue;
  document: operations.Document;
  exportModel: operations.ExportModel;
  farmapromPharmacy: operations.FarmapromPharmacy;
  importModel: operations.ImportModel;
  maintenance: operations.Maintenance;
  order: operations.Order;
  orderGroup: operations.OrderGroup;
  pharmacies: operations.Pharmacies;
  pharmacy: operations.Pharmacy;
  pharmacyTaxNote: operations.PharmacyTaxNote;
  pharmacyTaxNoteGroup: operations.PharmacyTaxNoteGroup;
  pharmacyTaxNotePayment: operations.PharmacyTaxNotePayment;
  pharmacyTaxNotePaymentConfirmation: operations.PharmacyTaxNotePaymentConfirmation;
  price: operations.Price;
  priceList: operations.PriceList;
  product: operations.Product;
  report: operations.Report;
  templateNote: operations.TemplateNote;
  umxPharmacy: operations.UmxPharmacy;
  voivodeship: operations.Voivodeship;
  voivodeshipContact: operations.VoivodeshipContact;
  voivodeshipPaymentGroup: operations.VoivodeshipPaymentGroup;

  /**
   * Initializes a new instance of the PolandPharmacyRxAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.PolandPharmacyRxAPIOptions) {
    super(credentials, options);
    this.account = new operations.Account(this);
    this.dictionary = new operations.Dictionary(this);
    this.dictionaryValue = new operations.DictionaryValue(this);
    this.document = new operations.Document(this);
    this.exportModel = new operations.ExportModel(this);
    this.farmapromPharmacy = new operations.FarmapromPharmacy(this);
    this.importModel = new operations.ImportModel(this);
    this.maintenance = new operations.Maintenance(this);
    this.order = new operations.Order(this);
    this.orderGroup = new operations.OrderGroup(this);
    this.pharmacies = new operations.Pharmacies(this);
    this.pharmacy = new operations.Pharmacy(this);
    this.pharmacyTaxNote = new operations.PharmacyTaxNote(this);
    this.pharmacyTaxNoteGroup = new operations.PharmacyTaxNoteGroup(this);
    this.pharmacyTaxNotePayment = new operations.PharmacyTaxNotePayment(this);
    this.pharmacyTaxNotePaymentConfirmation = new operations.PharmacyTaxNotePaymentConfirmation(this);
    this.price = new operations.Price(this);
    this.priceList = new operations.PriceList(this);
    this.product = new operations.Product(this);
    this.report = new operations.Report(this);
    this.templateNote = new operations.TemplateNote(this);
    this.umxPharmacy = new operations.UmxPharmacy(this);
    this.voivodeship = new operations.Voivodeship(this);
    this.voivodeshipContact = new operations.VoivodeshipContact(this);
    this.voivodeshipPaymentGroup = new operations.VoivodeshipPaymentGroup(this);
  }
}

// Operation Specifications

export {
  PolandPharmacyRxAPI,
  PolandPharmacyRxAPIContext,
  Models as PolandPharmacyRxAPIModels,
  Mappers as PolandPharmacyRxAPIMappers
};
export * from "./operations";
