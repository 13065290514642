import { Divider, Space } from 'antd';
import React from 'react';

import { PharmaciesNoteGroupsAddNote } from './PharmaciesNoteGroupsAddNote';

export const PharmaciesNoteGroupsMenu = () => {
	return (
		<>
			<Divider />
			<Space direction="horizontal">
				<PharmaciesNoteGroupsAddNote />
			</Space>
			<Divider />
		</>
	);
};
