import { PharmaciesNoteGroupsAddNote } from '@components/pharmaciesNoteGroups/PharmaciesNoteGroupsAddNote';
import { BackButton } from '@components/shared/buttons/BackButton';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { PharmacyTaxNoteGroupDto } from '@services/src/models';
import { Divider, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { IDictionaryStore } from 'stores/DictionaryStore';

import styles from './styles/PharmacyNotes.module.less';

interface IProps {
	noteGroup: PharmacyTaxNoteGroupDto;
}

export const PharmacyNotesMenu = (props: IProps) => {
	const group = props.noteGroup;
	const history = useHistory();

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const getHeader = () => {
		return `${group.pharmacyName} (${dictionaryStore.getValueById(
			DictionariesConstants.Voivodeships,
			group.voivodeshipId!
		)}, ${group.placeId}) ${group.month}/${group.year}`;
	};

	return (
		<>
			<Divider />

			<Space direction="horizontal" className={styles.menu}>
				<BackButton
					customAction={() => history.push('/pharmacySettlements')}
				/>
				<PharmaciesNoteGroupsAddNote
					pharmacyId={props.noteGroup.pharmacyId}
				/>
				<h2>{getHeader()}</h2>
			</Space>
			<Divider />
		</>
	);
};
