import { CheckCircleTwoTone } from '@ant-design/icons';
import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import * as OrderStateMapper from '@services/mappers/OrderState';
import * as TaxNoteOrderStateMapper from '@services/mappers/TaxNoteOrderState';
import { OrderDto, OrderState, TaxNoteOrderState } from '@services/src/models';
import { Tag } from 'antd';
import React from 'react';

const GetTagByOrderState = (state: OrderState) => {
	return (
		<Tag color={OrderStateMapper.mapColor(state)}>
			{OrderStateMapper.map(state)}
		</Tag>
	);
};

const GetTagByPaymentState = (state: TaxNoteOrderState) => {
	return <Tag color="#5055cd">{TaxNoteOrderStateMapper.map(state)}</Tag>;
};

export const OrdersTableColumns: EssityColumnProps<OrderDto>[] = [
	{
		dataIndex: 'orderState',
		key: 'orderState',
		title: 'Status NFZ',
		render: (state: OrderState) => GetTagByOrderState(state),
		width: 110,
		sorter: true,
		filter: 'enum' as filterType,
		enumMapper: OrderStateMapper,
		filterDictionary: OrderState,
	},
	{
		dataIndex: 'taxNoteOrderState',
		key: 'taxNoteOrderState',
		title: 'Status płatności',
		render: (state: TaxNoteOrderState) => GetTagByPaymentState(state),
		width: 135,
		sorter: true,
		filter: 'enum' as filterType,
		enumMapper: TaxNoteOrderStateMapper,
		filterDictionary: TaxNoteOrderState,
	},
	{
		sorter: true,
		dataIndex: 'year',
		key: 'year',
		title: 'Rok',
		filter: 'equals' as filterType,
		width: 60
	},
	{
		sorter: true,
		dataIndex: 'month',
		key: 'month',
		title: 'Miesiąc',
		filter: 'equals' as filterType,
		width: 60
	},
	{
		hidden: true,
		dataIndex: 'id',
		key: 'id',
		title: 'Id',
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'internalNumber',
		key: 'internalNumber',
		title: 'Numer wewnętrzny',
		width: 120,
	},
	{
		sorter: true,
		dataIndex: 'realizationId',
		key: 'realizationId',
		title: 'ID realizacji',
		width: 120,
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'orderNumber',
		key: 'orderNumber',
		title: 'Numer zlecenia',
		filter: 'contains' as filterType,
		width: 150,
	},
	{
		sorter: true,
		dataIndex: 'pharmacyName',
		key: 'pharmacyName',
		title: 'Apteka',
		filter: 'equals' as filterType,
		width: 140,
	},
	{
		sorter: false,
		dataIndex: 'placeId',
		key: 'placeId',
		title: 'SAP ID',
		width: 100,
		filter: 'equals' as filterType,
	},
	{
		dataIndex: 'pesel',
		key: 'pesel',
		title: 'PESEL',
		filter: 'equals' as filterType,
		width: 120,
	},
	{
		sorter: true,
		dataIndex: 'refund',
		key: 'refund',
		title: 'Refundacja',
		width: 120,
	},
	{
		sorter: true,
		dataIndex: 'value',
		key: 'value',
		title: 'Wartość',
		width: 120,
	},
];
