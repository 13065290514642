import { MenuItemSchema, SubMenuItemSchema } from './MenuItems';

export const menuItems: Array<MenuItemSchema | SubMenuItemSchema> = [
	{
		path: 'orders',
		text: 'Zlecenia',
		key: 'orders',
		activePrefix: ['orders', 'order'],
	},
	{
		path: 'pharmacySettlements',
		text: 'Moje płatności',
		key: 'pharmacySettlements',
		activePrefix: [
			'pharmacyPayments',
			'pharmacyCreateNote',
			'pharmacyEditNote',
			'pharmacyNote',
			'pharmacySettlements'
		],
	},
];
