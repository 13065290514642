import './TableSideBar.less';

import { Layout, Menu } from 'antd';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IGridStore } from 'stores/GridStore';

const { Sider } = Layout;
export interface ISideFilter {
	label: string | React.ReactElement;
	key: string;
	filter: string | undefined;
	defaultSelected?: boolean;
	subItems?: ISideFilter[];
	hint?: string;
}

interface IProps {
	gridName: string;
	filters: ISideFilter[];
	selectedFilter?: string;
	path: string;
	siderWidth?: number;
}

const TableSideBar: React.FC<IProps> = (props) => {
	const { filters, selectedFilter, path, siderWidth } = props;
	let history = useHistory();

	const defaultKeys = filters
		.filter((filter) => filter.defaultSelected)
		.map((filter) => filter.key);

	const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultKeys);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const pushRouteFilter = (filter: string) =>
		history.push(`/${path}/${filter}`);

	useEffect(() => {
		if (selectedFilter) {
			setSelectedKeys([selectedFilter]);
		}
		gridStore.setBackTab(selectedFilter);
	}, [selectedFilter]);

	const openKeys = filters?.find((f) => {
		if (!f.subItems) return false;
		return f.subItems.some((si) => si?.key === selectedFilter);
	})?.key;

	return (
		<Sider
			width={siderWidth ?? 150}
			className="site-layout-background sidebar-layout"
			style={{ paddingBottom: 48 }}
		>
			<Menu
				mode="inline"
				defaultSelectedKeys={defaultKeys}
				selectedKeys={selectedKeys}
				defaultOpenKeys={openKeys === undefined ? [] : [openKeys]}
				inlineIndent={6}
				onSelect={(param) => {
					const { selectedKeys } = param;
					if (selectedKeys) {
						setSelectedKeys(selectedKeys as string[]);
						pushRouteFilter(selectedKeys[0] as string);
					}
				}}
				style={{ height: '100%' }}
			></Menu>
		</Sider>
	);
};

export default observer(TableSideBar);
