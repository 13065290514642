import AddressSection from '@components/shared/AddressSection';
import { useTitleHook } from '@hooks/useTitleHook';
import React from 'react';

import { PharmaciesNoteGroupsMenu } from './PharmaciesNoteGroupsMenu';
import { PharmaciesNoteGroupsTable } from './PharmaciesNoteGroupsTable';
import styles from './styles/PharmaciesNoteGroups.module.less';

export const PharmaciesNoteGroups = () => {
	useTitleHook('Moje płatności');

	return (
		<div className={styles.noteGroups}>
			<AddressSection />
			<PharmaciesNoteGroupsMenu />

			<div className={styles.tableContainer}>
				<PharmaciesNoteGroupsTable />
			</div>
		</div>
	);
};
