import { useRootData } from '@hooks/hook';
import { Button } from 'antd';
import React from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

export const BackToSearchButton = () => {
	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	return (
		<Button
			shape="round"
			size="large"
			type="primary"
			onClick={() => {
				globalSearchStore.visible.set(true);
				globalSearchStore.filterData.set({
					...globalSearchStore.filterData.get(),
					selectedOrderId: undefined,
				});
			}}
		>
			Wróć do wyszukiwania
		</Button>
	);
};
