import './EssityColumnExtender.less';

import { Space } from 'antd';
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface';
import React, { Key, ReactNode } from 'react';
import { GridNameType } from 'stores/TableColumnStore';

import FilterButton from '../buttons/FilterButton';
import GridClearFilters from './filters/GridClearFilters';
import { GridFilterDateRange } from './filters/GridFilterDateRange';
import { GridFilterDictionarySelect } from './filters/GridFilterDictionarySelect';
import { GridFilterEnumSelect } from './filters/GridFilterEnumSelect';
import GridFilterInput from './filters/GridFilterInput';
import { EssityColumnProps } from './GridHelper';

export class EssityColumnExtender<T> {
	private gridName: GridNameType;
	private columnsSelector: boolean;
	private showClearFilterButton: boolean;

	constructor(
		gridName: GridNameType,
		columnsSelector: boolean | undefined,
		hidePersonalizationSettings: boolean | undefined,
		showClearFilterButton: boolean | undefined
	) {
		this.gridName = gridName;
		this.columnsSelector = columnsSelector ? columnsSelector : false;
		this.showClearFilterButton = showClearFilterButton ?? false;
	}

	public getActionButtons(
		columns: EssityColumnProps<T>[],
		search?: () => void
	) {
		if (columns === undefined || columns.length === 0) return <></>;
		return (
			<Space className="actions-container">
				{search !== undefined &&
					columns.some((a) => a.filter !== undefined) && (
						<GridClearFilters
							gridName={this.gridName}
							handleSearch={search}
						/>
					)}

				{search !== undefined &&
					!columns.some((a) => a.filter !== undefined) &&
					this.showClearFilterButton && (
						<GridClearFilters
							gridName={this.gridName}
							handleSearch={search}
						/>
					)}
			</Space>
		);
	}

	public applyFiltersAndSorter(
		columns: EssityColumnProps<T>[],
		antSorter: SorterResult<T>,
		search: () => void,
		isColumnFiltered: (columnKey: Key) => boolean
	): EssityColumnProps<T>[] {
		if (columns === undefined || columns.length === 0) return [];

		let columnsWithFiltersProps: EssityColumnProps<T>[] = [];

		columns
			.filter((x: EssityColumnProps<T>) => !x.hidden)
			.forEach((column) => {
				let columnWithFilterProps: EssityColumnProps<T>;

				if (column.filter !== undefined) {
					columnWithFilterProps = {
						...column,
						...this.getColumnFilterProps(
							column,
							search,
							isColumnFiltered
						),
					};
				} else {
					columnWithFilterProps = { ...column };
				}

				if (column.key === antSorter?.columnKey) {
					columnWithFilterProps.sortOrder = antSorter?.order;
				} else {
					columnWithFilterProps.sortOrder = null;
				}

				if (columnWithFilterProps.children) {
					columnWithFilterProps.children = this.applyFiltersAndSorter(
						columnWithFilterProps.children,
						antSorter,
						search,
						isColumnFiltered
					);
				}

				columnsWithFiltersProps.push(columnWithFilterProps);
			});

		return columnsWithFiltersProps;
	}

	private getColumnFilterProps = (
		column: EssityColumnProps<T>,
		search: () => void,
		isColumnFiltered: (columnKey: Key) => boolean
	) => ({
		filterDropdown: (filterProps: FilterDropdownProps): ReactNode => {
			return this.getFilterDropdownInput(column, filterProps, search);
		},
		filterIcon: () => {
			return (
				!column.hideFilterIcon && (
					<FilterButton
						id={`filterColumn-${column.key}`}
						filtered={isColumnFiltered(column.key!)}
					/>
				)
			);
		},
	});

	private getFilterDropdownInput(
		column: EssityColumnProps<T>,
		filterProps: FilterDropdownProps,
		search: () => void
	): ReactNode {
		if (column.filter === 'contains' || column.filter === 'equals') {
			return (
				<GridFilterInput
					column={column}
					filterProps={filterProps}
					handleSearch={search}
					gridName={this.gridName}
				/>
			);
		} else if (column.filter === 'dictionary') {
			if (column.filterDictionary === undefined)
				throw 'Dictionary type is required.';

			return (
				<GridFilterDictionarySelect
					column={column}
					filterProps={filterProps}
					handleSearch={search}
					gridName={this.gridName}
				/>
			);
		} else if (column.filter === 'dateRange') {
			return (
				<GridFilterDateRange
					column={column}
					filterProps={filterProps}
					handleSearch={search}
					gridName={this.gridName}
				/>
			);
		} else if (column.filter === 'enum') {
			return (
				<GridFilterEnumSelect
					column={column}
					filterProps={filterProps}
					handleSearch={search}
					gridName={this.gridName}
				/>
			);
		} else {
			throw `Filter ${column.filter} not supported`;
		}
	}
}
