import CallbackPage from '@authentication/CallbackPage';
import Login from '@authentication/Login';
import { LogoutPage } from '@authentication/LogoutPage';
import { GlobalUserManager } from '@authentication/userManager';
import { CreatedDocuments } from '@components/createdDocuments/CreatedDocuments';
import { ImportedDocuments } from '@components/importedDocuments/ImportedDocuments';
import { OrderLines } from '@components/orderLines/OrderLines';
import Orders from '@components/orders/Orders';
import { PharmaciesNoteGroups } from '@components/pharmaciesNoteGroups/PharmaciesNoteGroups';
import { PharmacyNote } from '@components/pharmacyNote/PharmacyNote';
import { PharmacyNotes } from '@components/pharmacyNotes/PharmacyNotes';
import RegisterPharmacy from '@components/register/RegisterPharmacy';
import TermsOfUseModal, {
	ITermsOfUseModal,
} from '@components/termsOfUse/TermsOfUseModal';
import { useRootData } from '@hooks/hook';
import { useCheckSessionHook } from '@hooks/useCheckSessionHook';
import { useIdleHook } from '@hooks/useIdleHook';
import { getEssityApiClient } from '@services/EssityApi';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter,
} from 'react-router-dom';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';
import SecuredPrimaryView from 'views/SecuredPrimaryView';

interface IProps {}

const App: React.FC<RouteComponentProps<any> & IProps> = () => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useIdleHook();

	useCheckSessionHook();


	return (
		<>
			<Spin
				tip="Trwa ładowanie..."
				spinning={
					!dictionaryStore.initialized.get() &&
					accountDetailsStore.oidcUser.get() !== undefined
				}
			>
				{accountDetailsStore.oidcUser.get() && (
					<Switch>
						<Route
							path="/"
							exact={true}
							render={(props) => <Redirect to="/orders" />}
						/>
						<Route
							path="/orders"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView content={<Orders />} />
							)}
						/>
						<Route
							path="/order/:orderId"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={
										<OrderLines
											orderId={props.match.params.orderId}
											isInSearch={false}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacySettlements"
							render={(props) => (
								<SecuredPrimaryView
									content={<PharmaciesNoteGroups />}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyPayments/:groupId/:pharmacyId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNotes
											groupId={props.match.params.groupId}
											pharmacyId={
												props.match.params.pharmacyId
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyNote/:id"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNote
											noteId={props.match.params.id}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyCreateNote"
							render={(props) => (
								<SecuredPrimaryView
									content={<PharmacyNote />}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyEditNote/:id"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNote
											noteId={props.match.params.id}
											editMode={true}
										/>
									}
								/>
							)}
						/>
						<Route
							path="/importedDocuments"
							render={(props) => (
								<SecuredPrimaryView
									content={<ImportedDocuments />}
								/>
							)}
						/>
						<Route
							path="/createdDocuments"
							render={(props) => (
								<SecuredPrimaryView
									content={<CreatedDocuments />}
								/>
							)}
						/>
						<Route
							path="/logout"
							render={(props) => <LogoutPage />}
						/>
					</Switch>
				)}
			</Spin>

			<Switch>
				<Route
					path="/"
					exact={true}
					render={(props) => <Redirect to="/login" />}
				/>
				<Route
					path="/authCallback"
					render={(props) => <CallbackPage />}
				/>
				<Route
					exact={true}
					path="/login"
					render={(props) => <Login />}
				/>
				<Route
					exact={true}
					path="/register/:id/:token"
					render={(props) => (
						<RegisterPharmacy
							id={props.match.params.id}
							token={props.match.params.token}
						/>
					)}
				/>
			</Switch>
		</>
	);
};

export default withRouter(observer(App));
