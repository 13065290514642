import { Modal } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useState } from 'react';

import * as PDFScrollHelper from '../../helper/pdfScrollHelper';

export enum ContentType {
	None,
	TermsOfUse = 'TermsOfUse.pdf',
	PrivacyPolicy = 'PrivacyPolicy.pdf',
	CookiesPolicy = 'CookiesPolicy.pdf',
}

interface IProps {
	contentType: ContentType;
	onCancel: () => void;
}

export const StaticContentModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [pdfFrameKey] = useState<number>(Math.random());

	useEffect(() => {
		if (props.contentType === ContentType.None) {
			setVisible(false);
		} else {
			setVisible(true);
		}
	}, [props.contentType]);

	return (
		<Modal
			visible={visible}
			maskClosable={false}
			centered
			closable={false}
			destroyOnClose
			keyboard={false}
			width="60vw"
			okButtonProps={{ hidden: true }}
			cancelText="Zamknij"
			onCancel={() => {
				props.onCancel();
				setVisible(false);
			}}
		>
			<CenteredRow>
				<iframe
					onLoad={PDFScrollHelper.resetScroll}
					key={pdfFrameKey}
					src={`/pdfjs/web/viewer.html?file=${
						process.env.PUBLIC_URL
					}/${props.contentType.toString()}`}
					width="100%"
					height="700px"
				></iframe>
			</CenteredRow>
		</Modal>
	);
};
