/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNoteGroupMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNoteGroup. */
export class PharmacyTaxNoteGroup {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNoteGroup.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupAllForPharmacyResponse>
   */
  allForPharmacy(options?: Models.PharmacyTaxNoteGroupAllForPharmacyOptionalParams): Promise<Models.PharmacyTaxNoteGroupAllForPharmacyResponse>;
  /**
   * @param callback The callback
   */
  allForPharmacy(callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  allForPharmacy(options: Models.PharmacyTaxNoteGroupAllForPharmacyOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>): void;
  allForPharmacy(options?: Models.PharmacyTaxNoteGroupAllForPharmacyOptionalParams | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>): Promise<Models.PharmacyTaxNoteGroupAllForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allForPharmacyOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupAllForPharmacyResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupGetByIdForPharmacyResponse>
   */
  getByIdForPharmacy(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGroupGetByIdForPharmacyResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getByIdForPharmacy(id: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getByIdForPharmacy(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>): void;
  getByIdForPharmacy(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>): Promise<Models.PharmacyTaxNoteGroupGetByIdForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdForPharmacyOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupGetByIdForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupAllResponse>
   */
  all(options?: Models.PharmacyTaxNoteGroupAllOptionalParams): Promise<Models.PharmacyTaxNoteGroupAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.PharmacyTaxNoteGroupAllOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>): void;
  all(options?: Models.PharmacyTaxNoteGroupAllOptionalParams | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDtoPagedResult>): Promise<Models.PharmacyTaxNoteGroupAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupAllResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGroupGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupDto>): Promise<Models.PharmacyTaxNoteGroupGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  changeState(options?: Models.PharmacyTaxNoteGroupChangeStateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  changeState(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeState(options: Models.PharmacyTaxNoteGroupChangeStateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  changeState(options?: Models.PharmacyTaxNoteGroupChangeStateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      changeStateOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/pharmacyTaxNoteGroup/PharmacyTaxNoteGroup",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteGroupDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getByIdForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/pharmacyTaxNoteGroup/PharmacyTaxNoteGroup/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteGroupDto
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroup",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.businessObjectsState,
    Parameters.settlementPeriodFilterCodeName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteGroupDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroup/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteGroupDto
    },
    default: {}
  },
  serializer
};

const changeStateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroup/changeState",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ChangeStatePharmacyTaxNoteGroupCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
