/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNoteMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNote. */
export class PharmacyTaxNote {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNote.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGetByIdForPharmacyResponse>
   */
  getByIdForPharmacy(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGetByIdForPharmacyResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getByIdForPharmacy(id: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getByIdForPharmacy(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>): void;
  getByIdForPharmacy(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>): Promise<Models.PharmacyTaxNoteGetByIdForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdForPharmacyOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGetByIdForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreateForPharmacyResponse>
   */
  createForPharmacy(options?: Models.PharmacyTaxNoteCreateForPharmacyOptionalParams): Promise<Models.PharmacyTaxNoteCreateForPharmacyResponse>;
  /**
   * @param callback The callback
   */
  createForPharmacy(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createForPharmacy(options: Models.PharmacyTaxNoteCreateForPharmacyOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  createForPharmacy(options?: Models.PharmacyTaxNoteCreateForPharmacyOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreateForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createForPharmacyOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreateForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteUpdateForPharmacyResponse>
   */
  updateForPharmacy(options?: Models.PharmacyTaxNoteUpdateForPharmacyOptionalParams): Promise<Models.PharmacyTaxNoteUpdateForPharmacyResponse>;
  /**
   * @param callback The callback
   */
  updateForPharmacy(callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateForPharmacy(options: Models.PharmacyTaxNoteUpdateForPharmacyOptionalParams, callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  updateForPharmacy(options?: Models.PharmacyTaxNoteUpdateForPharmacyOptionalParams | msRest.ServiceCallback<Models.UpdateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): Promise<Models.PharmacyTaxNoteUpdateForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateForPharmacyOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteUpdateForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteForPharmacy(options?: Models.PharmacyTaxNoteDeleteForPharmacyOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteForPharmacy(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteForPharmacy(options: Models.PharmacyTaxNoteDeleteForPharmacyOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteForPharmacy(options?: Models.PharmacyTaxNoteDeleteForPharmacyOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteForPharmacyOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteDetailsDto>): Promise<Models.PharmacyTaxNoteGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreateResponse>
   */
  create(options?: Models.PharmacyTaxNoteCreateOptionalParams): Promise<Models.PharmacyTaxNoteCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.PharmacyTaxNoteCreateOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  create(options?: Models.PharmacyTaxNoteCreateOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.PharmacyTaxNoteDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.PharmacyTaxNoteDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.PharmacyTaxNoteDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteUpdateResponse>
   */
  update(options?: Models.PharmacyTaxNoteUpdateOptionalParams): Promise<Models.PharmacyTaxNoteUpdateResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.PharmacyTaxNoteUpdateOptionalParams, callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  update(options?: Models.PharmacyTaxNoteUpdateOptionalParams | msRest.ServiceCallback<Models.UpdateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): Promise<Models.PharmacyTaxNoteUpdateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteUpdateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreateCorrectionResponse>
   */
  createCorrection(options?: Models.PharmacyTaxNoteCreateCorrectionOptionalParams): Promise<Models.PharmacyTaxNoteCreateCorrectionResponse>;
  /**
   * @param callback The callback
   */
  createCorrection(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createCorrection(options: Models.PharmacyTaxNoteCreateCorrectionOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  createCorrection(options?: Models.PharmacyTaxNoteCreateCorrectionOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreateCorrectionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createCorrectionOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreateCorrectionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreateDebitCorrectionResponse>
   */
  createDebitCorrection(options?: Models.PharmacyTaxNoteCreateDebitCorrectionOptionalParams): Promise<Models.PharmacyTaxNoteCreateDebitCorrectionResponse>;
  /**
   * @param callback The callback
   */
  createDebitCorrection(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createDebitCorrection(options: Models.PharmacyTaxNoteCreateDebitCorrectionOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  createDebitCorrection(options?: Models.PharmacyTaxNoteCreateDebitCorrectionOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreateDebitCorrectionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createDebitCorrectionOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreateDebitCorrectionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreateLossResponse>
   */
  createLoss(options?: Models.PharmacyTaxNoteCreateLossOptionalParams): Promise<Models.PharmacyTaxNoteCreateLossResponse>;
  /**
   * @param callback The callback
   */
  createLoss(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createLoss(options: Models.PharmacyTaxNoteCreateLossOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  createLoss(options?: Models.PharmacyTaxNoteCreateLossOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreateLossResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createLossOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreateLossResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreatePayLossResponse>
   */
  createPayLoss(options?: Models.PharmacyTaxNoteCreatePayLossOptionalParams): Promise<Models.PharmacyTaxNoteCreatePayLossResponse>;
  /**
   * @param callback The callback
   */
  createPayLoss(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createPayLoss(options: Models.PharmacyTaxNoteCreatePayLossOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  createPayLoss(options?: Models.PharmacyTaxNoteCreatePayLossOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreatePayLossResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createPayLossOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreatePayLossResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteUpdatePayLossResponse>
   */
  updatePayLoss(options?: Models.PharmacyTaxNoteUpdatePayLossOptionalParams): Promise<Models.PharmacyTaxNoteUpdatePayLossResponse>;
  /**
   * @param callback The callback
   */
  updatePayLoss(callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePayLoss(options: Models.PharmacyTaxNoteUpdatePayLossOptionalParams, callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  updatePayLoss(options?: Models.PharmacyTaxNoteUpdatePayLossOptionalParams | msRest.ServiceCallback<Models.UpdateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): Promise<Models.PharmacyTaxNoteUpdatePayLossResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updatePayLossOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteUpdatePayLossResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteUpdateLossResponse>
   */
  updateLoss(options?: Models.PharmacyTaxNoteUpdateLossOptionalParams): Promise<Models.PharmacyTaxNoteUpdateLossResponse>;
  /**
   * @param callback The callback
   */
  updateLoss(callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoss(options: Models.PharmacyTaxNoteUpdateLossOptionalParams, callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  updateLoss(options?: Models.PharmacyTaxNoteUpdateLossOptionalParams | msRest.ServiceCallback<Models.UpdateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): Promise<Models.PharmacyTaxNoteUpdateLossResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateLossOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteUpdateLossResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteUpdateCorrectionResponse>
   */
  updateCorrection(options?: Models.PharmacyTaxNoteUpdateCorrectionOptionalParams): Promise<Models.PharmacyTaxNoteUpdateCorrectionResponse>;
  /**
   * @param callback The callback
   */
  updateCorrection(callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCorrection(options: Models.PharmacyTaxNoteUpdateCorrectionOptionalParams, callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  updateCorrection(options?: Models.PharmacyTaxNoteUpdateCorrectionOptionalParams | msRest.ServiceCallback<Models.UpdateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): Promise<Models.PharmacyTaxNoteUpdateCorrectionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateCorrectionOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteUpdateCorrectionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteUpdateOverpayResponse>
   */
  updateOverpay(options?: Models.PharmacyTaxNoteUpdateOverpayOptionalParams): Promise<Models.PharmacyTaxNoteUpdateOverpayResponse>;
  /**
   * @param callback The callback
   */
  updateOverpay(callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateOverpay(options: Models.PharmacyTaxNoteUpdateOverpayOptionalParams, callback: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): void;
  updateOverpay(options?: Models.PharmacyTaxNoteUpdateOverpayOptionalParams | msRest.ServiceCallback<Models.UpdateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.UpdateNoteResponseDto>): Promise<Models.PharmacyTaxNoteUpdateOverpayResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOverpayOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteUpdateOverpayResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionResponse>
   */
  getPharmacyTaxNotesPredictedToCorrection(options?: Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionOptionalParams): Promise<Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionResponse>;
  /**
   * @param callback The callback
   */
  getPharmacyTaxNotesPredictedToCorrection(callback: msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPharmacyTaxNotesPredictedToCorrection(options: Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto[]>): void;
  getPharmacyTaxNotesPredictedToCorrection(options?: Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionOptionalParams | msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto[]>, callback?: msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto[]>): Promise<Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getPharmacyTaxNotesPredictedToCorrectionOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGetPredictedReturnOverpayResponse>
   */
  getPredictedReturnOverpay(options?: Models.PharmacyTaxNoteGetPredictedReturnOverpayOptionalParams): Promise<Models.PharmacyTaxNoteGetPredictedReturnOverpayResponse>;
  /**
   * @param callback The callback
   */
  getPredictedReturnOverpay(callback: msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPredictedReturnOverpay(options: Models.PharmacyTaxNoteGetPredictedReturnOverpayOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto>): void;
  getPredictedReturnOverpay(options?: Models.PharmacyTaxNoteGetPredictedReturnOverpayOptionalParams | msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto>, callback?: msRest.ServiceCallback<Models.PharmacyNotePredictedToCorrectionDto>): Promise<Models.PharmacyTaxNoteGetPredictedReturnOverpayResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getPredictedReturnOverpayOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGetPredictedReturnOverpayResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionResponse>
   */
  recountPharmacyTaxNoteCorrection(options?: Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionOptionalParams): Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionResponse>;
  /**
   * @param callback The callback
   */
  recountPharmacyTaxNoteCorrection(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  recountPharmacyTaxNoteCorrection(options: Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionOptionalParams, callback: msRest.ServiceCallback<string>): void;
  recountPharmacyTaxNoteCorrection(options?: Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      recountPharmacyTaxNoteCorrectionOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayResponse>
   */
  recountPharmacyTaxNoteOverpay(options?: Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayOptionalParams): Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayResponse>;
  /**
   * @param callback The callback
   */
  recountPharmacyTaxNoteOverpay(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  recountPharmacyTaxNoteOverpay(options: Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayOptionalParams, callback: msRest.ServiceCallback<string>): void;
  recountPharmacyTaxNoteOverpay(options?: Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      recountPharmacyTaxNoteOverpayOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteRecountPharmacyTaxNoteOverpayResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteAssignPaymentConfirmationResponse>
   */
  assignPaymentConfirmation(options?: Models.PharmacyTaxNoteAssignPaymentConfirmationOptionalParams): Promise<Models.PharmacyTaxNoteAssignPaymentConfirmationResponse>;
  /**
   * @param callback The callback
   */
  assignPaymentConfirmation(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  assignPaymentConfirmation(options: Models.PharmacyTaxNoteAssignPaymentConfirmationOptionalParams, callback: msRest.ServiceCallback<string>): void;
  assignPaymentConfirmation(options?: Models.PharmacyTaxNoteAssignPaymentConfirmationOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNoteAssignPaymentConfirmationResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      assignPaymentConfirmationOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteAssignPaymentConfirmationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteDeletePaymentConfirmationsResponse>
   */
  deletePaymentConfirmations(options?: Models.PharmacyTaxNoteDeletePaymentConfirmationsOptionalParams): Promise<Models.PharmacyTaxNoteDeletePaymentConfirmationsResponse>;
  /**
   * @param callback The callback
   */
  deletePaymentConfirmations(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePaymentConfirmations(options: Models.PharmacyTaxNoteDeletePaymentConfirmationsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  deletePaymentConfirmations(options?: Models.PharmacyTaxNoteDeletePaymentConfirmationsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNoteDeletePaymentConfirmationsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deletePaymentConfirmationsOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteDeletePaymentConfirmationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteCreateOverpayResponse>
   */
  createOverpay(options?: Models.PharmacyTaxNoteCreateOverpayOptionalParams): Promise<Models.PharmacyTaxNoteCreateOverpayResponse>;
  /**
   * @param callback The callback
   */
  createOverpay(callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createOverpay(options: Models.PharmacyTaxNoteCreateOverpayOptionalParams, callback: msRest.ServiceCallback<Models.CreateNoteResponseDto>): void;
  createOverpay(options?: Models.PharmacyTaxNoteCreateOverpayOptionalParams | msRest.ServiceCallback<Models.CreateNoteResponseDto>, callback?: msRest.ServiceCallback<Models.CreateNoteResponseDto>): Promise<Models.PharmacyTaxNoteCreateOverpayResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOverpayOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteCreateOverpayResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/pharmacyTaxNote/PharmacyTaxNote/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteDetailsDto
    },
    default: {}
  },
  serializer
};

const createForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "portal/pharmacyTaxNote/PharmacyTaxNote",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePharmacyTaxNoteCommandForPharmacy
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const updateForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "portal/pharmacyTaxNote/PharmacyTaxNote",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyTaxNoteCommandForPharmacy
  },
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const deleteForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "portal/pharmacyTaxNote/PharmacyTaxNote",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeletePharmacyTaxNoteCommandForPharmacy
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNote/PharmacyTaxNote/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteDetailsDto
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "pharmacyTaxNote/PharmacyTaxNote",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeletePharmacyTaxNoteCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNote/PharmacyTaxNote",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const createCorrectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/createCorrection",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateCorrectionPharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const createDebitCorrectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/createDebitCorrection",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateDebitCorrectionPharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const createLossOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/createLoss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateLossPharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const createPayLossOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/createPayLoss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePayLossPharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const updatePayLossOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNote/PharmacyTaxNote/updatePayLoss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePayLossPharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const updateLossOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNote/PharmacyTaxNote/updateLoss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateLossPharmacyTaxNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const updateCorrectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNote/PharmacyTaxNote/updateCorrection",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyTaxNoteCorrectionCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const updateOverpayOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNote/PharmacyTaxNote/updateOverpay",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyTaxOverpayNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNoteResponseDto
    },
    default: {}
  },
  serializer
};

const getPharmacyTaxNotesPredictedToCorrectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNote/PharmacyTaxNote/pharmacyTaxNotesPredictedToCorrection",
  queryParameters: [
    Parameters.pharmacyTaxNoteGroupId0,
    Parameters.internalTaxNoteType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyNotePredictedToCorrectionDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPredictedReturnOverpayOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNote/PharmacyTaxNote/predictedReturnOverpay",
  queryParameters: [
    Parameters.pharmacyTaxNoteId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyNotePredictedToCorrectionDto
    },
    default: {}
  },
  serializer
};

const recountPharmacyTaxNoteCorrectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/recountPharmacyTaxNoteCorrection",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RecountPharmacyTaxNoteCorrectionCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const recountPharmacyTaxNoteOverpayOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/recountPharmacyTaxNoteOverpay",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RecountPharmacyTaxNoteOverpayCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const assignPaymentConfirmationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/assignPaymentConfirmation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AssignPaymentConfirmationCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deletePaymentConfirmationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "pharmacyTaxNote/PharmacyTaxNote/deletePaymentConfirmations",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeletePaymentConfirmationsCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const createOverpayOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNote/PharmacyTaxNote/createOverpay",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePharmacyTaxOverpayNoteCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.CreateNoteResponseDto
    },
    default: {}
  },
  serializer
};
