import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { PharmacyTaxNoteGroupDto } from '@services/src/models';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';

const ApplyValidation = (item: string, noteGroup: PharmacyTaxNoteGroupDto) => {
	if (noteGroup.noteValue === noteGroup.settledOrdersValue) {
		return item;
	}
	return <div className="validationError">{item}</div>;
};

export const GetPharmaciesNoteGroupsTableColumns = (): EssityColumnProps<PharmacyTaxNoteGroupDto>[] => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	return [
		{
			sorter: true,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Wojewódźtwo',
			filter: 'equals' as filterType,
			hideFilterIcon: true,
			render: (value: number, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(
					dictionaryStore.getValueById(
						DictionariesConstants.Voivodeships,
						value
					)!,
					noteGroup
				),
		},
		{
			sorter: true,
			dataIndex: 'pharmacyName',
			key: 'pharmacyName',
			title: 'Apteka',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'placeId',
			key: 'placeId',
			title: 'SAP ID',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'month',
			key: 'month',
			title: 'Miesiąc',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'year',
			key: 'year',
			title: 'Rok',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'noteValue',
			key: 'noteValue',
			title: 'Wartość not',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'ordersValue',
			key: 'ordersValue',
			title: 'Wartość zleceń',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'settledOrdersValue',
			key: 'settledOrdersValue',
			title: 'Rozliczone NFZ',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'correctionValue',
			key: 'correctionValue',
			title: 'Wartość korekty',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'paid',
			key: 'paid',
			title: 'Zapłacono',
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'balance',
			key: 'balance',
			title: 'Saldo bieżące',
			width: 125,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
	];
};