import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { OrderLineDetailsDto } from '@services/src/models';

export const OrderLinesTableColumns: EssityColumnProps<OrderLineDetailsDto>[] = [
	{
		dataIndex: 'productId',
		key: 'productId',
		title: 'Kod produktu',
		width: 150,
	},
	{
		dataIndex: 'productName',
		key: 'productName',
		title: 'Nazwa',
		width: 400,
	},
	{
		dataIndex: 'productVersionCode',
		key: 'productVersionCode',
		title: 'Kod Producenta',
		width: 150,
	},
	{
		dataIndex: 'qty',
		key: 'qty',
		title: 'Ilość',
		width: 150,
	},
	{
		dataIndex: 'price',
		key: 'price',
		title: 'Cena',
		width: 150,
	},
	{
		dataIndex: 'value',
		key: 'value',
		title: 'Wartość',
		width: 150,
	},
];
