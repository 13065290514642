import { FilterFilled } from '@ant-design/icons';
import clsx from 'clsx';
import EssityTooltip from 'layout/EssityTooltip';
import React from 'react';

interface IProps {
	id?: string;
	ariaLabel?: string;
	filtered: boolean;
}

const FilterButton: React.FC<IProps> = (props) => {
	const { filtered } = props;

	return (
		<EssityTooltip placement="top" title="Filtruj">
			<FilterFilled
				className={clsx(filtered && 'filter-active')}
				style={{ color: 'black' }}
			/>
		</EssityTooltip>
	);
};

FilterButton.defaultProps = {
	ariaLabel: 'Filter column',
};

export default FilterButton;
