import * as Yup from 'yup';

export const RegisterPharmacyValidationSchema = () =>
	Yup.object().shape({
		password: Yup.string().required('Hasło jest wymagane'),
		passwordConfirmation: Yup.string().test(
			'passwords-match',
			'Hasła muszą być takie same',
			function (value) {
				return this.parent.password === value;
			}
		),
	});
