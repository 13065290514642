import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { Col, Input, Modal } from 'antd';
import { Formik } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { User } from 'oidc-client';
import React from 'react';

interface IProps {
	user: User;
	visible: boolean;
	setVisible: (state: boolean) => void;
}

export const MyProfile = (componentProps: IProps) => {
	const handleCancel = () => {
		componentProps.setVisible(false);
	};

	return (
		<IsolatedContainer>
			<Modal
				visible={componentProps.visible}
				title="Mój profil"
				cancelText="Close"
				width="30vw"
				centered
				okButtonProps={{ hidden: true }}
				cancelButtonProps={{ hidden: true }}
				maskClosable={false}
				onCancel={handleCancel}
				closable={true}
				destroyOnClose
			>
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={{}}
					enableReinitialize
					validationSchema={{}}
					onSubmit={() => {}}
					render={() => (
						<CenteredRow>
							<Col span={24}>
								<FFieldLabel label="Nazwa apteki" />
								<Input
									value={`${componentProps.user.profile.name}`}
									readOnly
								/>
							</Col>
							<Col span={24}>
								<FFieldLabel label="Email" />
								<Input
									value={
										componentProps.user.profile.emails[0]
									}
									readOnly
								/>
							</Col>
						</CenteredRow>
					)}
				></Formik>
			</Modal>
		</IsolatedContainer>
	);
};
