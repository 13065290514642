import objectHelpers from '@helper/objectHelpers';
import {
	Checkbox,
	DatePicker,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Space,
	TimePicker,
} from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { CheckboxProps } from 'antd/lib/checkbox';
import { ColProps } from 'antd/lib/col';
import Collapse, { CollapsePanelProps } from 'antd/lib/collapse';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import { InputProps } from 'antd/lib/input';
import { InputNumberProps } from 'antd/lib/input-number';
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RowProps } from 'antd/lib/row';
import { SelectProps } from 'antd/lib/select';
import Switch, { SwitchProps } from 'antd/lib/switch';
import clsx from 'clsx';
import { FieldProps, FormikErrors, FormikProps, getIn } from 'formik';
import formatHelpers, { DateFormat } from 'helper/formatHelpers';
import _ from 'lodash';
import { Moment } from 'moment';
import * as React from 'react';
import { Component, InputHTMLAttributes, createRef } from 'react';
import DayPicker, { DayPickerProps, Modifier } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { findDOMNode } from 'react-dom';
import { RadioGroupProps } from 'reakit/ts';

import AsyncAutocomplete, {
	IAsyncAutocompleteProps,
} from './AsyncAutocomplete';
import { ErrorPopover } from './ErrorPopover';

const { RangePicker } = DatePicker;

export function isNotValidAndTouched(props: FieldProps) {
	return isNotValidAndTouchedField(props.form, props.field.name);
}

export function isNotValidAndTouchedField(
	form: FormikProps<any>,
	fieldName: string
) {
	let getInError = getIn(form.errors, fieldName);
	let getInTouched = getIn(form.touched, fieldName);

	return getInError && (getInTouched || form.submitCount > 0)
		? getInError
		: '';
}

export function shouldShowErrorMessage(
	form: FormikProps<any>,
	fieldName: string
) {
	let notValid = isNotValidAndTouchedField(form, fieldName);

	const hasErrorMessage =
		typeof objectHelpers.flattenKeys(form.errors)[fieldName] == 'string' &&
		getIn(form.errors, fieldName);

	if (notValid && !hasErrorMessage) return false;

	return notValid;
}

export class FErrorMessage extends React.Component<{
	errors: FormikErrors<any>;
	name: string;
}> {
	public render() {
		let errorMessage: JSX.Element | string | undefined = undefined;
		const rawMessage = getIn(this.props.errors, this.props.name);
		const errorMessageType = typeof objectHelpers.flattenKeys(
			this.props.errors
		)[this.props.name];

		if (errorMessageType === 'string') {
			errorMessage = rawMessage;
		} else if (Array.isArray(rawMessage)) {
			errorMessage = (
				<>
					{rawMessage
						.filter((error) => typeof error === 'string')
						.map((error) => (
							<p>{error}</p>
						))}
				</>
			);
		}

		return <div className={'form-error-label'}>{errorMessage}</div>;
	}
}

export class FArrayErrorMessage extends React.Component<{ message: any }> {
	public render() {
		return typeof this.props.message === 'string' ? (
			<div className="form-error form-error-label">
				{this.props.message}
			</div>
		) : null;
	}
}

export class FDirectErrorMessage extends React.Component<{ message: any }> {
	public render() {
		return this.props.message !== undefined ? (
			<div className="form-error form-error-label">
				{this.props.message}
			</div>
		) : null;
	}
}

export interface IFFieldLabelProps {
	label: string;
	optional?: boolean;
	for?: string;
}

export class FFieldLabel extends React.Component<IFFieldLabelProps & ColProps> {
	static defaultProps = {};

	public render() {
		return (
			<label
				className={
					this.props.optional ? 'form-label-optional' : 'form-label'
				}
				htmlFor={this.props.for}
			>
				{this.props.label}
				{this.props.optional && ' (optional)'}
			</label>
		);
	}
}

export class FRow extends React.Component<RowProps> {
	static defaultProps = {
		gutter: 16,
	};
	public render() {
		return <Row {...this.props}>{this.props.children}</Row>;
	}
}

export interface IFormikInputProps extends FieldProps<any> {}

interface IFInputProps {
	changeData: (value: any) => void;
	label?: string;
}

export class FInput extends React.Component<
	IFormikInputProps & InputProps & IFInputProps & InputHTMLAttributes<Input>,
	{}
> {
	public render() {
		const splitName = this.props.field.name.split('.');

		const input = () => {
			return (
				<Input
					{...this.props.field}
					id={this.props.id ? this.props.id : this.props.field.name}
					className={this.props.className}
					disabled={this.props.disabled}
					readOnly={this.props.readOnly}
					maxLength={this.props.maxLength}
					aria-label={this.props.label}
					autoFocus={this.props.autoFocus}
					bordered={this.props.bordered}
					autoComplete={this.props.autoComplete}
					onBlur={(event) => {
						if (
							splitName.length > 1 &&
							this.props.form.touched[splitName[0]]
						)
							this.props.form.setFieldTouched(
								splitName[0],
								false
							);
						this.props.onBlur
							? this.props.onBlur(event)
							: this.props.field.onBlur(event);
					}}
					onChange={(value) => {
						this.props.form.setFieldValue(
							this.props.field.name,
							value.target.value
						);
						if (this.props.changeData) {
							this.props.changeData(value.target.value);
						}
					}}
					onClick={this.props.onClick}
					type="text"
				/>
			);
		};
		return (
			<ErrorPopover field={this.props.field} form={this.props.form}>
				{input()}
			</ErrorPopover>
		);
	}
}


export interface IFormikInputProps extends FieldProps<any> {
	disabled?: boolean;
	label?: string;
	rows?: number;
}

export class FTextArea extends React.Component<
	IFormikInputProps &
		TextAreaProps &
		InputHTMLAttributes<TextAreaProps> & {
			changeData: (value: any) => void;
		},
	{}
> {
	input = () => {
		return (
			<TextArea
				{...this.props}
				{...this.props.field}
				id={this.props.id ? this.props.id : this.props.field.name}
				onChange={(value) => {
					this.props.form.setFieldValue(
						this.props.field.name,
						value.target.value
					);
					if (this.props.changeData) {
						this.props.changeData(value.target.value);
					}
				}}
				aria-label={this.props.label}
				rows={this.props.rows}
			/>
		);
	};
	public render() {
		return (
			<ErrorPopover field={this.props.field} form={this.props.form}>
				{this.input()}
			</ErrorPopover>
		);
	}
}

export class FPasswordInput extends React.Component<
	IFormikInputProps & InputProps & InputHTMLAttributes<Input>,
	{}
> {
	public render() {
		const input = () => {
			return (
				<Input.Password
					{...this.props}
					type="text"
					{...this.props.field}
				/>
			);
		};
		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

interface FInputNumberProps {
	changeData: Function;
	label?: string;
	name?: string;
}

export class FInputNumber extends React.Component<
	IFormikInputProps & InputNumberProps & FInputNumberProps,
	{}
> {
	static defaultProps = {
		precision: 2,
		min: 0,
		max: 2147483647,
	};
	private ref = createRef<any>();

	componentDidUpdate(
		oldProps: IFormikInputProps & InputNumberProps & FInputNumberProps
	) {
		if (this.ref.current) {
			(findDOMNode(this.ref.current!) as HTMLElement).removeAttribute(
				'aria-label'
			);
		}
	}

	public render() {
		const input = () => {
			return (
				<InputNumber
					{...this.props}
					{...this.props.field}
					id={this.props.id ? this.props.id : this.props.field.name}
					className={clsx(this.props.className,
						this.props.readOnly && 'ant-input-number-readonly'
					)}
					precision={this.props.precision}
					ref={this.ref}
					aria-label={this.props.label}
					onChange={(v: string | number | undefined) => {
						if (v && formatHelpers.numberRegex.test(v as string)) {
							this.props.form.setFieldValue(
								this.props.field.name,
								v
							);
						} else
							this.props.form.setFieldValue(
								this.props.field.name,
								0
							);
						this.props.changeData && this.props.changeData(v);
					}}
				/>
			);
		};

		return (
			<ErrorPopover field={this.props.field} form={this.props.form}>
				{input()}
			</ErrorPopover>
		);
	}
}

interface IFDatePicker {
	setValue?: (val: any) => void;
	changeData?: Function;
	label?: string;
}

export class FDatePicker extends React.Component<
	IFormikInputProps & DatePickerProps & IFDatePicker,
	{}
> {
	public render() {
		const {
			inputReadOnly,
			disabled,
			className,
			placeholder,
			format,
			id,
			...restProps
		} = this.props;
		const input = () => {
			return (
				<DatePicker
					id={id ? id : this.props.field.name}
					className={clsx(
						className,
						inputReadOnly && 'ant-picker-readonly'
					)}
					disabledDate={
						this.props.disabledDate && this.props.disabledDate
					}
					value={this.props.field.value}
					getPopupContainer={(el) => el}
					placeholder={inputReadOnly ? '' : placeholder}
					{...restProps}
					disabled={disabled || inputReadOnly}
					inputReadOnly={inputReadOnly}
					allowClear={!disabled && !inputReadOnly}
					format={format ? format : DateFormat.Date}
					onOpenChange={(o) => {
						if (!o) {
							this.props.form.setFieldTouched(
								this.props.field.name,
								true
							);
						}
					}}
					onChange={(v: Moment | null) => {
						if (v === null || v === undefined) {
							this.props.form.setFieldValue(
								this.props.field.name,
								undefined
							);
						} else {
							this.props.form.setFieldValue(
								this.props.field.name,
								v
							);
						}
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
						if (typeof this.props.setValue == 'function') {
							this.props.setValue(v);
						}
						
						if(this.props.changeData) {
							this.props.changeData(v);
						}
					}}
					aria-label={this.props.label}
				/>
			);
		};

		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

interface FDayPickerState {
	initialMonth: Date | undefined;
	label?: string;
}

export class FDayPicker extends React.Component<
	IFormikInputProps & DayPickerProps,
	FDayPickerState
> {
	constructor(props: DayPickerProps & IFormikInputProps) {
		super(props);
		this.state = {
			initialMonth: undefined,
		};
	}

	private transformIfMoment(value: any) {
		if (value && Array.isArray(value)) {
			return value.map((d) => {
				if (d._isAMomentObject) {
					return d.toDate();
				}

				return d;
			});
		}
		return value;
	}

	public render() {
		const input = () => {
			return (
				<DayPicker
					onDayClick={(v: Date) => {
						if (this.props.disabled) {
							return;
						}
						this.setState({ initialMonth: v });

						this.props.initialMonth!.setHours(0, 0, 0, 0);
						this.props.toMonth!.setHours(23, 59, 59, 999);

						if (
							v >= this.props.initialMonth! &&
							v <= this.props.toMonth!
						) {
							if (this.props.field.value === undefined) {
								let value: Modifier[] = [v];
								this.props.form.setFieldValue(
									this.props.field.name,
									value
								);
							} else if (
								(this.props.field
									.value as Modifier[]).find(
									(value: Modifier) =>
										DayPicker.DateUtils.isSameDay(
											value as Date,
											v
										)
								)
							) {
								const selectedIndex = (this.props.field
									.value as Modifier[]).findIndex(
									(selectedDay) =>
										DayPicker.DateUtils.isSameDay(
											selectedDay as Date,
											v
										)
								);
								(this.props.field.value as Modifier[]).splice(
									selectedIndex,
									1
								);
								this.props.form.setFieldValue(
									this.props.field.name,
									this.props.field.value as Modifier[]
								);
							} else {
								(this.props.field.value as Modifier[]).push(v);
								this.props.form.setFieldValue(
									this.props.field.name,
									this.props.field.value as Modifier[]
								);
							}
						}
						this.props.form.setFieldTouched(
							this.props.field.name,
							true,
							true
						);
					}}
					initialMonth={
						this.state.initialMonth == undefined
							? this.props.initialMonth
							: this.state.initialMonth
					}
					fromMonth={this.props.initialMonth}
					toMonth={this.props.toMonth}
					locale="pl"
					localeUtils={MomentLocaleUtils}
					selectedDays={this.transformIfMoment(
						this.props.field.value
					)}
					disabledDays={this.props.disabledDays}
					className={
						isNotValidAndTouched(this.props)
							? 'form-date-error '
							: ''
					}
					aria-label={this.props.label}
				/>
			);
		};
		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

export interface IFSelectProps extends FieldProps<any> {
	onChangeAttempt?: (newValue: string) => boolean;
	changeData?: (value: any) => void;
	allowClear?: boolean;
	readOnly?: boolean;
	label?: string;
}

export class FSelect extends React.Component<
	IFSelectProps & IFormikInputProps /*& SelectProps*/,
	{}
> {
	private ref = createRef<HTMLSelectElement>();

	componentDidMount() {
		if (this.ref.current) {
			(findDOMNode(this.ref.current) as HTMLElement).removeAttribute(
				'aria-label'
			);
		}
	}

	componentDidUpdate(
		oldProps: IFormikInputProps & SelectProps<any>
	) {}
	public render() {
		const { changeData, readOnly, ...restProps } = this.props;

		const input = () => {
			return (
				<Select
					ref={this.ref}
					allowClear={this.props.allowClear ?? true}
					placeholder={readOnly ? '' : 'Wybierz'}
					{...restProps}
					optionFilterProp="children"
					aria-label={this.props.label}
					id={this.props.field.name}
					aria-owns={this.props.field.name}
					aria-controls={this.props.field.name}
					aria-activedescendant={this.props.field.name}
					value={
						Array.isArray(this.props.field.value)
							? this.props.field.value
							: this.props.field.value
							? this.props.field.value.toString()
							: undefined
					}
					disabled={this.props.disabled || readOnly}
					onBlur={(v: any) => {
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
					}}
					onChange={(v: any, y: any) => {
						if (
							this.props.onChangeAttempt === undefined ||
							this.props.onChangeAttempt(v)
						) {
							this.props.form.setFieldValue(
								this.props.field.name,
								v
							);
							this.props.form.setFieldTouched(
								this.props.field.name,
								true
							);
						}
						if (changeData) {
							changeData(v);
						}
					}}
				>
					{this.props.children}
				</Select>
			);
		};

		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

export class FTimePicker extends React.Component<IFormikInputProps, {}> {
	public render() {
		const input = () => {
			return (
				<TimePicker
					{...this.props}
					value={this.props.field.value}
					onOpenChange={(o) => {
						if (!o) {
							this.props.form.setFieldTouched(
								this.props.field.name,
								true
							);
						}
					}}
					onChange={(value: Moment | null) => {
						if (value === null || value === undefined) {
							this.props.form.setFieldValue(
								this.props.field.name,
								undefined
							);
						} else {
							this.props.form.setFieldValue(
								this.props.field.name,
								value
							);
						}
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);

						//todo po aktualizacji do FORMIK 2 może być potrzebny taki drobny HACK (Zamiast w/w setFieldTouched):
						//setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));
					}}
					aria-label={this.props.label}
				/>
			);
		};

		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

export interface IFSelectProps extends FieldProps<any> {
	onChangeAttempt?: (newValue: string) => boolean;
	changeData?: (value: any) => void;
	allowClear?: boolean;
	readOnly?: boolean;
	label?: string;
}
export interface IFSelectSubjectsProps extends FieldProps<any> {
	onChangeAttempt?: (newValue: string) => boolean;
	search?: (val: string) => any;
	othername?: string;
	mode?: 'multiple' | undefined;
	array?: any;
	editable?: boolean;
	dropDown?: boolean;
	readOnly?: boolean;
	tagRender?: boolean;
	onClose?: (val: string) => boolean;
	label?: string;
	allowClear?: boolean;
}
interface IFPanelProps {
	shouldHighlight: () => boolean;
}

export class FPanel extends React.Component<CollapsePanelProps & IFPanelProps> {
	public render() {
		return (
			<Collapse.Panel
				{...this.props}
				className={
					this.props.shouldHighlight() ? 'form-panel-error' : ''
				}
			>
				{this.props.children}
			</Collapse.Panel>
		);
	}
}

export function isFieldInArrayError(
	props: any,
	errors: any,
	index: number,
	fieldPatterns: string[]
) {
	return (
		props &&
		errors &&
		errors[index] &&
		isFieldError(
			{
				errors: errors[index],
				submitCount: props.submitCount,
			},
			fieldPatterns
		)
	);
}

export function isFieldError(props: any, fieldPatterns: string[]) {
	for (let error in props.errors)
		for (let i = 0; i < fieldPatterns.length; i++) {
			let fieldName = fieldPatterns[i];

			if (fieldPatterns[i].endsWith('*')) {
				fieldName = fieldPatterns[i].slice(
					0,
					fieldPatterns[i].length - 1
				);

				if (error.startsWith(fieldName) && props.submitCount)
					return true;
			} else {
				if (error === fieldPatterns[i] && props.submitCount)
					return true;
			}
		}

	return false;
}

interface IFCheckbox {
	label?: string;
}

export class FCheckbox extends React.Component<
	IFormikInputProps & CheckboxProps & IFCheckbox
> {
	constructor(props: IFormikInputProps & IFCheckbox) {
		super(props);
	}
	public render() {
		const input = () => {
			return (
				<Checkbox
					{...this.props}
					checked={this.props.field.value}
					onChange={(e) => {
						this.props.onChange && this.props.onChange(e);
						this.props.form.setFieldValue(
							this.props.field.name,
							e.target.checked
						);
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
					}}
					aria-label={this.props.label}
				/>
			);
		};
		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

export class FRangePicker extends React.Component<
	IFormikInputProps & RangePickerProps & IFDatePicker,
	{}
> {
	private ref = createRef<Component<RangePickerProps, any, any>>();
	constructor(props: IFormikInputProps & RangePickerProps & IFDatePicker) {
		super(props);
	}

	componentDidMount() {
		this.setAttributes();
	}

	componentDidUpdate(
		oldProps: IFormikInputProps & RangePickerProps & IFDatePicker
	) {
		this.setAttributes();
	}

	private setAttributes() {
		const rangePickers = document.querySelectorAll('.ant-picker-range');
		rangePickers.forEach((rangePicker) => {
			const inputs = rangePicker.querySelectorAll('input');

			for (let i = 0; i < inputs.length; i++) {
				const input = inputs[i];
				input.setAttribute(
					'aria-label',
					input.placeholder || `Pole datowe ${i}`
				);
			}
		});
		const separators = document.querySelectorAll('.ant-picker-separator');
		separators.forEach((separator) => {
			separator?.setAttribute('role', 'separator');
		});

		const antPickerPanels = document.querySelectorAll(
			'div, [class="ant-picker-date-panel"]'
		);

		antPickerPanels.forEach((panel) => {
			panel.querySelectorAll('button').forEach((button) => {
				if (!button.getAttribute('aria-label')) {
					button.setAttribute('aria-hidden', 'true');
				}
			});
		});
	}

	public render() {
		const {
			inputReadOnly,
			disabled,
			placeholder,
			format,
			id,
			...restProps
		} = this.props;

		const input = () => {
			return (
				<RangePicker
					className={clsx(inputReadOnly && 'ant-picker-readonly')}
					id={id ? id : this.props.field.name}
					value={this.props.field.value}
					{...restProps}
					ref={this.ref}
					disabled={disabled || inputReadOnly}
					aria-label={this.props.label}
					inputReadOnly={inputReadOnly}
					placeholder={inputReadOnly ? ['', ''] : placeholder}
					allowClear={!disabled && !inputReadOnly}
					format={format ? format : DateFormat.Date}
					onOpenChange={(o) => {
						if (!o) {
							this.props.form.setFieldTouched(
								this.props.field.name,
								true
							);
						}

						setTimeout(() => this.setAttributes(), 500);
					}}
					onChange={(v: [Moment | null, Moment | null] | null) => {
						if (v === null || v === undefined) {
							this.props.form.setFieldValue(
								this.props.field.name,
								undefined
							);
						} else {
							this.props.form.setFieldValue(
								this.props.field.name,
								v
							);
						}
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
						if (typeof this.props.setValue == 'function') {
							this.props.setValue(v);
						}
						this.props.changeData && this.props.changeData(v);
					}}
				/>
			);
		};

		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

export class FAsyncAutocomplete extends React.Component<
	IAsyncAutocompleteProps & IFormikInputProps & AutoCompleteProps
> {
	constructor(
		props: IAsyncAutocompleteProps & IFormikInputProps & AutoCompleteProps
	) {
		super(props);
	}

	componentDidMount() {
		const fieldNode = document.getElementById(this.props.field.name);
		const parentSelectDiv = fieldNode?.closest('.ant-select');
		parentSelectDiv?.removeAttribute('aria-label');
	}

	public render() {
		const splitName = this.props.field.name.split('.');

		const input = () => {
			return (
				<AsyncAutocomplete
					value={this.props.field.value}
					id={this.props.field.name}
					{...this.props}
					disabled={this.props.disabled}
					autoFocus={this.props.autoFocus}
					onClick={this.props.onClick}
					onSearch={(data: string) => {
						this.props.form.setFieldValue(
							this.props.field.name,
							data
						);
					}}
					onBlur={() => {
						if (
							splitName.length > 1 &&
							this.props.form.touched[splitName[0]]
						)
							this.props.form.setFieldTouched(
								splitName[0],
								false
							);

						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
					}}
					aria-label={this.props.label}
				/>
			);
		};
		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

interface IFInputFile {
	getFile?: (value: File) => void;
	accept?: string;
	label?: string;
}

export class FInputFile extends React.Component<
	IFormikInputProps & InputProps & IFInputFile & InputHTMLAttributes<Input>,
	{}
> {
	public render() {
		const input = () => {
			return (
				<Input
					{...this.props.field}
					onBlur={
						this.props.onBlur
							? this.props.onBlur
							: this.props.field.onBlur
					}
					onChange={(value) => {
						this.props.form.setFieldValue(
							this.props.field.name,
							value.target.value
						);
						if (this.props.getFile)
							this.props.getFile(value.target.files?.[0]!);
					}}
					type="file"
					accept={this.props.accept}
					aria-label={this.props.label}
				/>
			);
		};
		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>
			</>
		);
	}
}

interface IFSwitch {
	label?: string;
	trueLabel: string;
	falseLabel: string;
	trueValue: any;
	falseValue: any;
}

export class FSwitch extends React.Component<
	IFormikInputProps & SwitchProps & IFSwitch
> {
	constructor(props: IFormikInputProps & SwitchProps & IFSwitch) {
		super(props);
	}
	componentDidMount() {}
	public render() {
		return (
			<Space>
				<span>{this.props.falseLabel}</span>
				<Switch
					{...this.props}
					onChange={(e) => {
						this.props.form.setFieldValue(
							this.props.field.name,
							e ? this.props.trueValue : this.props.falseValue
						);
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
					}}
					checked={this.props.field.value == this.props.trueValue}
					aria-label={this.props.label}
				/>
				<span>{this.props.trueLabel}</span>
			</Space>
		);
	}
}

interface IFFieldValueProps {
	label: string | React.ReactNode;
	optional?: boolean;
	for?: string;
}

export class FFieldValue extends React.Component<IFFieldValueProps & ColProps> {
	static defaultProps = {};
	public render() {
		return (
			<>
				{this.props.optional && (
					<label
						className={`form-label-optional ${this.props.className}`}
					>
						{this.props.label} {' (optional)'}
					</label>
				)}
				{(this.props.optional === undefined ||
					this.props.optional === false) && (
					<label
						className={`form-label ${this.props.className}`}
						htmlFor={this.props.for}
					>
						{this.props.label}
					</label>
				)}
			</>
		);
	}
}

interface FRadioGroupProps {
	changeData: Function;
	labels: string[];
	values: string[];
	defaultValue: string;
	name: string;
}

export class FRadioGroup extends React.Component<
	IFormikInputProps & RadioGroupProps & FRadioGroupProps,
	{}
> {
	private ref = createRef<any>();

	componentDidUpdate(
		oldProps: IFormikInputProps & RadioGroupProps & FRadioGroupProps
	) {
		if (this.ref.current) {
			(findDOMNode(this.ref.current!) as HTMLElement).removeAttribute(
				'aria-label'
			);
		}
	}

	public render() {
		const startValue = this.props.field.value ?? this.props.defaultValue;
		if (!this.props.field.value)
			this.props.form.setFieldValue(
				this.props.field.name,
				this.props.defaultValue
			);

		const input = () => {
			return (
				<Radio.Group
					disabled={this.props.disabled}
					name={this.props.name}
					value={startValue}
					ref={this.ref}
					aria-label={this.props.label}
					onChange={(e: RadioChangeEvent) => {
						this.props.form.setFieldValue(
							this.props.field.name,
							e.target.value
						);

						this.props.changeData &&
							this.props.changeData(e.target.value);
					}}
				>
					{this.props.values.map((v, i) => {
						return <Radio value={v}>{this.props.labels[i]}</Radio>;
					})}
				</Radio.Group>
			);
		};

		return (
			<ErrorPopover field={this.props.field} form={this.props.form}>
				{input()}
			</ErrorPopover>
		);
	}
}
