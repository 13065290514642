export const PaymentTableColumns = [
    {
        sorter: false,
        dataIndex: 'paymentName',
        key: 'paymentName',
        title: 'Nazwa płatności',
        width: 150,
        align: 'left',
    },
    {
        title: '',
        width: 1000,
    },
];