import {
	ShoppingCartOutlined,
	SnippetsOutlined,
	UserOutlined,
} from '@ant-design/icons';
import formatHelpers from '@helper/formatHelpers';
import { OrderDetailsDto, OrderErrorDto } from '@services/src/models';
import { Descriptions, Space } from 'antd';
import EssityTable from 'layout/EssityTable';
import EssityTooltip from 'layout/EssityTooltip';
import React from 'react';

import { OrderLinesDetailsSkeleton } from './OrderLinesDetailsSkeleton';
import { OrderLinesTableColumns } from './OrderLinesTableColumns';
import styleModule from './styles/OrderLines.module.less';

interface IProps {
	orderDetails: OrderDetailsDto | undefined;
	theme?: { [key: string]: string };
	isInSearch: boolean;
}

export const OrderLinesDetails = (props: IProps) => {
	const styles = props.theme ? props.theme : styleModule;

	const { orderDetails } = props;

	return orderDetails ? (
		<div className={styles.details}>
			{orderDetails.orderErrors && orderDetails.orderErrors.length > 0 && (
				<Descriptions className={styles.warning} column={1}>
					{orderDetails.orderErrors?.map((error: OrderErrorDto) => (
						<Descriptions.Item span={1}>
							<p>{error.description}</p>
						</Descriptions.Item>
					))}
				</Descriptions>
			)}

			<div className={styles.section}>
				<Space className="header-text">
					<UserOutlined />
					Pacjent
				</Space>

				<Descriptions className={styles.description} column={3}>
					{!orderDetails.isEUPatient && orderDetails?.patientId && (
						<Descriptions.Item label="PESEL">
							<b>{orderDetails.patientId}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.orderNumber && (
						<Descriptions.Item label="Numer karty">
							<b>{orderDetails.orderNumber}</b>
						</Descriptions.Item>
					)}

					{orderDetails.isEUPatient && (
						<>
							{orderDetails.patientFirstname && (
								<Descriptions.Item label="Imię">
									<b>{orderDetails.patientFirstname}</b>
								</Descriptions.Item>
							)}

							{orderDetails.patientSurname && (
								<Descriptions.Item label="Nazwisko">
									<b>{orderDetails.patientSurname}</b>
								</Descriptions.Item>
							)}

							{orderDetails.patientDateOfBirth && (
								<Descriptions.Item label="Data urodzenia">
									<b>
										{formatHelpers.formatDate(
											orderDetails.patientDateOfBirth
										)}
									</b>
								</Descriptions.Item>
							)}
						</>
					)}
				</Descriptions>
			</div>

			<div className={styles.section}>
				<Space className="header-text">
					<SnippetsOutlined />
					Zlecenie
				</Space>
				<Descriptions
					className={styles.description}
					column={3}
					size="small"
				>
					{orderDetails?.pharmacyName && (
						<Descriptions.Item label="Apteka">
							<b>{orderDetails.pharmacyName}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.orderReceiptDate && (
						<Descriptions.Item label="Data odbioru zamówienia">
							<b>
								{formatHelpers.formatDate(
									orderDetails.orderReceiptDate
								)}
							</b>
						</Descriptions.Item>
					)}

					{orderDetails?.releaseDate && (
						<Descriptions.Item label="Data wydania">
							<b>
								{formatHelpers.formatDate(
									orderDetails.releaseDate
								)}
							</b>
						</Descriptions.Item>
					)}

					{orderDetails?.productReleaseDate && (
						<Descriptions.Item label="Data wydania produktu">
							<b>
								{formatHelpers.formatDate(
									orderDetails.productReleaseDate
								)}
							</b>
						</Descriptions.Item>
					)}

					{orderDetails?.realizationPeriod && (
						<Descriptions.Item label="Okres realizacji">
							<b>{orderDetails.realizationPeriod}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.releasePlace && (
						<Descriptions.Item label="Miejsce wydania">
							<b>{orderDetails.releasePlace}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.additionalEntitlementCode && (
						<Descriptions.Item label="Kod dodatkowego uprawnienia">
							<EssityTooltip
								placement="top"
								title={
									orderDetails.additionalEntitlementFullName
								}
							>
								<b>{orderDetails.additionalEntitlementCode}</b>
							</EssityTooltip>
						</Descriptions.Item>
					)}
				</Descriptions>
			</div>
			<div className={styles.section}>
				<Space className="header-text">
					<ShoppingCartOutlined />
					Produkty
				</Space>
				<Descriptions
					className={`${styles.description} ${styles.products}`}
				>
					<Descriptions.Item>
						<EssityTable
							columns={OrderLinesTableColumns}
							dataSource={props.orderDetails?.orderLines}
							pagination={false}
						/>
					</Descriptions.Item>
				</Descriptions>
			</div>

			<div className={styles.section}>
				<div className={`${styles.description} ${styles.summary}`}>
					<p className={styles.prefix}>Podsumowanie</p>
					<div>
						<p>Refundacja</p>
						<p className={styles.value}>
							{props.orderDetails?.summaryRefund}
						</p>
					</div>
					<div>
						<p>Dopłata</p>
						<p className={styles.value}>
							{props.orderDetails?.summarySurcharge}
						</p>
					</div>
					<div>
						<p>Wartość końcowa</p>
						<p className={styles.value}>
							{props.orderDetails?.summaryTotalValue}
						</p>
					</div>
				</div>
			</div>
		</div>
	) : (
		<OrderLinesDetailsSkeleton />
	);
};
