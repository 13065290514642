import { baseAppUrl } from '@helper/api';
import {
	UserManager,
	UserManagerSettings,
	WebStorageStateStore,
} from 'oidc-client';
import urljoin from 'url-join';
export class GlobalUserManager {
	private static _userManager: UserManager | undefined = undefined;

	public static Init(): void {
		GlobalUserManager._userManager = createUserManagerWithSettings();
		GlobalUserManager._userManager.events.addSilentRenewError(() => {
			this._userManager?.signoutRedirect();
		});

		// GlobalUserManager._userManager.events.addAccessTokenExpiring(
		// 	function () {
		// 		console.log('token expiring...');
		// 	}
		// );

		// Oidc.Log.logger = console;
	}

	public static UserManager = (): UserManager => {
		return GlobalUserManager._userManager!;
	};
}

function createUserManagerWithSettings(): UserManager {
	const resourceId = window._env_.REACT_APP_RESOURCE_ID; //GUID API Application ID

	var config: UserManagerSettings = {
		authority: `${window._env_.REACT_APP_DOMAIN}v2.0/.well-known/openid-configuration?p=${window._env_.REACT_APP_USERFLOW}`,
		client_id: window._env_.REACT_APP_CLIENT_ID,
		redirect_uri: urljoin(baseAppUrl, '/authCallback'),
		response_type: 'id_token token',
		scope: window._env_.REACT_APP_SCOPE,
		post_logout_redirect_uri: urljoin(baseAppUrl, '/login'),
		loadUserInfo: false,
		automaticSilentRenew: true,
		accessTokenExpiringNotificationTime: 120,
		silent_redirect_uri: urljoin(baseAppUrl, '/silent-renew.html'),
		userStore: new WebStorageStateStore({ store: window.localStorage }),
		extraQueryParams: {
			resource: resourceId,
		},
		filterProtocolClaims: true,
	};

	return new UserManager(config);
}
