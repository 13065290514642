import { ajaxByUser } from '@helper/api';
import { useTitleHook } from '@hooks/useTitleHook';
import { getEssityApiClient } from '@services/EssityApi';
import { RegisterAccountCommand } from '@services/src/models';
import { Button, Layout, Modal, Space } from 'antd';
import { Field, Formik, FormikActions } from 'formik';
import { FFieldLabel, FPasswordInput } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { Footer } from 'layout/Footer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';

import { RegisterPharmacyValidationSchema } from './RegisterPharmacyValidationSchema';

interface IProps {
	id: string;
	token: string;
}

const RegisterPharmacy = (props: IProps) => {
	useTitleHook('Rejestracja');

	const history = useHistory();

	const handleCreate = (
		values: RegisterAccountCommand,
		actions: FormikActions<RegisterAccountCommand>
	) => {
		ajaxByUser('Poprawnie zarejestrowano konto.', () =>
			getEssityApiClient().then((api) =>
				api.account
					.registerPharmacy({
						body: {
							password: values.password,
							pharmacyId: props.id,
							invitationCode: props.token
						},
					})
					.then(() => {
						actions.resetForm();
						Modal.success({
							centered: true,
							maskClosable: true,
							title: `Konto zostało poprawnie zarejestowane, zostaniesz przekierowany na stronę logowania.`,
							onOk: () => {
								history.push('/login');
							},
						});
					})
			)
		);
	};

	return (
		<Space direction="vertical">
			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				validationSchema={RegisterPharmacyValidationSchema}
				onSubmit={(
					values,
					actions: FormikActions<RegisterAccountCommand>
				) => handleCreate(values, actions)}
				render={(actions: FormikActions<RegisterAccountCommand>) => {
					return (
						<>
							<Layout.Content className="ant-layout-content--main">
								<CenteredRow>
									<Space direction="vertical">
										<img
											width={250}
											height={104}
											style={{
												marginLeft: 'auto',
												marginRight: 'auto',
												display: 'block',
											}}
											src={
												process.env.PUBLIC_URL +
												'/logo.png'
											}
										/>
										<p>
											<b>Zapraszamy</b> do rejestracji w
											portalu refundacyjnym, <br /> w tym
											celu prosimy o zdefiniowanie nowego
											hasła:
										</p>
										<FFieldLabel label="Hasło" />
										<Field
											component={FPasswordInput}
											placeholder="Hasło"
											name="password"
											readOnly={false}
										/>
										<FFieldLabel label="Powtórz hasło" />
										<Field
											component={FPasswordInput}
											placeholder="Powtórz hasło"
											name="passwordConfirmation"
											readOnly={false}
										/>
										<Button
											type="primary"
											size="large"
											shape="round"
											onClick={() => actions.submitForm()}
										>
											Zarejestruj
										</Button>
									</Space>
								</CenteredRow>
							</Layout.Content>

							<Footer />
						</>
					);
				}}
			/>
		</Space>
	);
};

export default observer(RegisterPharmacy);
