import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderDto } from '@services/src/models';
import React from 'react';
import { useHistory } from 'react-router';
import { ITableColumnStore } from 'stores/TableColumnStore';

import styles from './styles/Orders.module.less';

export const OrdersTable = () => {
	const gridName = 'orders';

	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const columns: EssityColumnProps<OrderDto>[] = [
		...tableColumnStore.getTable(gridName)
	];

	const history = useHistory();

	return (
		<PaginatedEssityTable<OrderDto>
			columns={columns}
			gridName={gridName}
			className={styles.table}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			sideBarFilters={undefined}
			onRow={(record: OrderDto) => {
				return {
					onDoubleClick: () => {
						history.push(`/order/${record.id}`);
					},
				};
			}}
			getRowKey={(r: OrderDto) => r.id!}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const api = await getEssityApiClient();
				return await api.order.allForPharmacy({
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
				});
			}}
		/>
	);
};
