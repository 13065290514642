import AddressSection from '@components/shared/AddressSection';
import { TableWithTitle } from '@components/shared/TableWithTitle';
import { useTitleHook } from '@hooks/useTitleHook';
import { observer } from 'mobx-react';
import React from 'react';

import { OrdersTable } from './OrdersTable';
import styles from './styles/Orders.module.less';

interface IProps {}

const Orders = (props: IProps) => {
	useTitleHook('Zlecenia');

	return (
		<div className={styles.orders}>
			<TableWithTitle headerComponent={<AddressSection />}>
				<div className={styles.tableContainer}>
					<OrdersTable />
				</div>
			</TableWithTitle>
		</div>
	);
};

export default observer(Orders);
