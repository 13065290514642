import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { PharmacyTaxNoteDto } from '@services/src/models';

export const PharmacyNotesTableColumns: EssityColumnProps<PharmacyTaxNoteDto>[] = [
	{
		sorter: false,
		dataIndex: 'taxNoteType',
		key: 'taxNoteType',
		title: 'Typ noty',
		width: 120,
	},
	{
		sorter: false,
		dataIndex: 'noteNumber',
		key: 'noteNumber',
		title: 'Numer noty',
	},
	{
		sorter: false,
		dataIndex: 'noteDate',
		key: 'noteDate',
		title: 'Data noty',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: false,
		dataIndex: 'noteValue',
		key: 'noteValue',
		title: 'Wartość noty',
		width: 100,
	},
	{
		sorter: false,
		dataIndex: 'ordersValue',
		key: 'ordersValue',
		title: 'Wartość zleceń',
		width: 100,
	},
	{
		sorter: false,
		dataIndex: 'settledOrdersValue',
		key: 'settledOrdersValue',
		title: 'Rozliczone NFZ',
		width: 100,
	},
];
