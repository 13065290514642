import { OrderState } from '@services/src/models';
import { observable } from 'mobx';
import moment from 'moment';

export interface IFilterData {
	searchText: string | undefined;
	searchVoivodeships: number[] | undefined;
	searchOrderStates: OrderState[] | undefined;
	searchType: number;
	dateFrom: moment.Moment | null;
	dateTo: moment.Moment | null;
	selectedOrderId?: string;
}

export const globalSearchStore = {
	visible: observable.box<boolean>(false),
	filterData: observable.box<IFilterData>({
		searchText: undefined,
		searchType: 1,
		searchVoivodeships: undefined,
		searchOrderStates: undefined,
		dateFrom: null,
		dateTo: null,
		selectedOrderId: undefined
	}),
};

export const createGlobalSearchStore = () => {
	return globalSearchStore;
};

export type IGlobalSearchStore = ReturnType<typeof createGlobalSearchStore>;
