import { GlobalUserManager } from '@authentication/userManager';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { User } from 'oidc-client';
import { useEffect } from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';

import { useRootData } from './hook';

export const useCheckSessionHook = () => {

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useEffect(() => {


		GlobalUserManager.UserManager()
			.getUser()
			.then((user: User | null) => {
				if (user) {
					accountDetailsStore.oidcUser.set(user);

					dictionaryStore.initializeStore([
						DictionariesConstants.Voivodeships,
						DictionariesConstants.SettlementPeriod,
						DictionariesConstants.NHFContract,
						DictionariesConstants.EndContractReason,
					]);
				}
			});
	}, []);
};
