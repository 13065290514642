import Modal from 'antd/lib/modal/Modal';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react';
import React, { useImperativeHandle, useState } from 'react';

import * as PDFScrollHelper from '../../helper/pdfScrollHelper';

interface IProps {
	allowCancel?: boolean;
}

export interface ITermsOfUseModal {
	showModal: () => void;
}

const TermsOfUseModal = React.forwardRef<ITermsOfUseModal, IProps>(
	(props, ref) => {
		const [visible, setVisible] = useState<boolean>(false);
		const [pdfFrameKey] = useState<number>(Math.random());

		useImperativeHandle(ref, () => ({
			showModal: () => {
				setVisible(true);
			},
		}));

		return (
			<Modal
				visible={visible}
				maskClosable={false}
				centered
				closable={props.allowCancel}
				destroyOnClose
				keyboard={false}
				width="60vw"
				title="Warunki użytkowania portalu"
				cancelText="Zamknij"
				okButtonProps={{ hidden: true }}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<CenteredRow>
					<iframe
						onLoad={PDFScrollHelper.resetScroll}
						key={pdfFrameKey}
						src={`/pdfjs/web/viewer.html?file=${process.env.PUBLIC_URL}/TermsOfUse.pdf`}
						width="100%"
						height="700px"
					></iframe>
				</CenteredRow>
			</Modal>
		);
	}
);

export default observer(TermsOfUseModal);
