import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PharmacyTaxNoteGroupAllOptionalParams,
	PharmacyTaxNoteGroupDto,
} from '@services/src/models';
import React from 'react';
import { useHistory } from 'react-router';

import { GetPharmaciesNoteGroupsTableColumns } from './PharmaciesNoteGroupsTableColumns';
import styles from './styles/PharmaciesNoteGroups.module.less';

export const PharmaciesNoteGroupsTable = () => {
	const gridName = 'pharmaciesNoteGroups';

	const history = useHistory();

	return (
		<PaginatedEssityTable<PharmacyTaxNoteGroupDto>
			className={styles.table}
			columns={GetPharmaciesNoteGroupsTableColumns()}
			gridName={gridName}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			sideBarFilters={undefined}
			getRowKey={(r: PharmacyTaxNoteGroupDto) => r.id!}
			onRow={(record: PharmacyTaxNoteGroupDto) => {
				return {
					onDoubleClick: () => {
						history.push(
							`/pharmacyPayments/${record.id}/${record.pharmacyId}`
						);
					},
				};
			}}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const parameters: PharmacyTaxNoteGroupAllOptionalParams = {
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
				};

				const api = await getEssityApiClient();
				return await api.pharmacyTaxNoteGroup.allForPharmacy(parameters);
			}}
		/>
	);
};
